import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: 10,
    backgroundColor: colors.primaryColor,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  goBackBtn: {
    width: 35,
    height: 35,
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
    marginRight: 19,
  },
  title: {
    fontFamily: fonts.medium,
    fontSize: RFValue(20),
    color: colors.white,
  },
  description: {
    fontFamily: fonts.light,
    fontSize: RFValue(14),
    color: colors.white,
  },
});

export { styles };
