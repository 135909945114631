import { DocumentData, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../config/firebase";
import { PropsReturn } from "../../../models/core.props-return";

interface GetPreRegistrationPropsReturn extends PropsReturn {
  data: DocumentData;
}

interface GetPreRegistrationProps {
  cpf: string;
  registration: string;
}

async function getPreRegistration(
  props: GetPreRegistrationProps
): Promise<GetPreRegistrationPropsReturn> {
  try {
    const { cpf, registration } = props;
    const userRef = doc(firestore, `/preRegistrations/${cpf}`);

    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const response = userSnapshot.data();

      if (response?.registration !== registration) {
        return {
          message: "Verifique seus dados e tente novamente!",
          errorCode: "400",
          data: {},
        };
      }

      return {
        message: "Pré-registro encontrado com sucesso.",
        data: response,
      };
    }
    return {
      message: "Pré-registro não existe!",
      errorCode: "404",
      data: {},
    };
  } catch (error: any) {
    return {
      message: "Não foi possível encontrar o usuário pré-registrado!",
      errorCode: error.code,
      errorMessage: error.message,
      data: {},
    };
  }
}

export { getPreRegistration };
