import React from "react";
import { StyleProp, View, ViewStyle, Image } from "react-native";
import { styles } from "./styles";

interface ThreeDotsLoadingProps {
  additionalStyles?: StyleProp<ViewStyle>;
}

const ThreeDotsLoading = (props: ThreeDotsLoadingProps) => {
  return (
    <View style={[styles.loadingContainer, props?.additionalStyles]}>
      <Image
        source={require("../../../../assets/gifs/three-dots-loading.gif")}
        style={styles.gif}
      />
    </View>
  );
};

export { ThreeDotsLoading };
