import { Fragment, useEffect, useState } from "react";
import { Linking, ScrollView, StatusBar, View } from "react-native";

import { NativeStackHeaderProps } from "@react-navigation/native-stack";

import {
  CardsWithStatus,
  IconProps,
} from "../../components/Cards/CardsWithStatus.tsx";
import { HeaderWithGoBack } from "../../components/Headers/HeaderWithGoBack";
import { NoDataMessage } from "../../components/Messages/NoDataMessage";

import { useAuth } from "../../hooks/useAuth";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";

import { getColor } from "../../styles/colors";
import { styles } from "./styles";

import { collection, onSnapshot, query, where } from "firebase/firestore";
import { BasicLoading } from "../../components/Loadings/BasicLoading";

import ConfirmationModal from "../../components/Modals/ConfirmationModal";

import { firestore } from "../../config/firebase";

import SafeAreaView from "react-native-safe-area-view";
import { isAppointmentOnTime } from "../../utils/appointment";

import {
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  subHours,
} from "date-fns";
import { AppointmentProps } from "../../models/appointmentProps.js";
import { specialtyIdToSpecialtyName } from "../../utils/doc24/appointment";
import { useCompany } from "../../hooks/useCompany";

export interface nextAppointmentProps {
  id: string;
  status: "late" | "on-time" | "abandon" | "";
}

const History = ({ navigation }: NativeStackHeaderProps) => {
  const { user } = useAuth();
  const { company } = useCompany();

  const [appointments, setAppointments] = useState<AppointmentProps[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });

  const statusColorAndTextByTheName = {
    cancelled: {
      text: "cancelada",
      color: colors.textRed,
    },
    open: {
      text: "agendada",
      color: colors.textOrange,
    },
    finished: {
      text: "realizada",
      color: colors.textGreen,
    },
    noShow: {
      text: "ausente",
      color: colors.darkGray,
    },
    inProgress: {
      text: "em progresso",
      color: colors.textGreen,
    },
    abandon: {
      text: "desistido",
      color: colors.darkGray,
    },
    default: {
      text: "",
      color: colors.darkGray,
    },
  };

  useEffect(() => {
    setIsFetchingData(true);
    const collectionRef = collection(firestore, "appointments");

    const cpfsList = [user.cpf];

    user?.dependents?.forEach((dependent) => {
      if (typeof dependent.cpf === "string") {
        cpfsList.push(dependent.cpf);
      }
    });

    const appointmentsQuery = query(
      collectionRef,
      where("patient.cpf", "in", cpfsList)
    );

    const unsubscribeDrTisAppointments = onSnapshot(
      appointmentsQuery,
      (querySnapShot) => {
        const _appointments: AppointmentProps[] = [];

        querySnapShot.docs.map((doc) => {
          const appointment: any = { ...doc.data() };
          _appointments.push(appointment);
        });

        setIsFetchingData(false);
        setAppointments(_appointments);
      }
    );

    return () => {
      unsubscribeDrTisAppointments();
    };
  }, [user.id, user.cpf]);

  if (isFetchingData) {
    return <BasicLoading />;
  }

  return (
    <SafeAreaView style={styles.container} forceInset={{ top: "always" }}>
      <StatusBar backgroundColor={colors.primaryColor} translucent={true} />
      <HeaderWithGoBack
        title="Histórico"
        description="Atendimento virtual"
        goBackFunction={() => navigation.goBack()}
      />
      <ScrollView>
        <View style={styles.contentWrapper}>
          {!!appointments?.length ? (
            appointments
              ?.filter((appointment) => !!appointment.appointmentAt)
              .map((appointment) => {
                const appointmentStatus = !!appointment && appointment?.status;

                let statusColor =
                  statusColorAndTextByTheName[appointmentStatus];

                if (!statusColor) {
                  statusColor = statusColorAndTextByTheName["default"];
                }

                let color = statusColor.color;
                let text = statusColor.text;

                const now = new Date();
                const appointmentDate = appointment?.appointmentAt?.toDate();

                const time = format(appointmentDate, "HH:mm");

                const fullDate = format(appointmentDate, "dd/MM/yyyy");

                const minutesDifference = differenceInMinutes(
                  appointmentDate,
                  now
                );

                const absMinutesDifference = Math.abs(minutesDifference);
                const missOneMinute = absMinutesDifference === 1;

                const lateText = `${absMinutesDifference} minuto${missOneMinute ? "" : "s"
                  } de atraso`;

                const onTimeText = `Falta${missOneMinute ? "" : "m"
                  } ${absMinutesDifference} minuto${missOneMinute ? "" : "s"}`;
                const subTitleText =
                  minutesDifference < 0 ? lateText : onTimeText;

                const isOnTime = isAppointmentOnTime(appointmentDate);

                const appointmentAvaliable =
                  isOnTime && appointment?.status === "open";

                const isInsideDayRange = isBefore(
                  subHours(appointmentDate, 24),
                  now
                );

                const textStatusCard = isAfter(
                  now,
                  appointment?.appointmentAt.toDate()
                )
                  ? "danger"
                  : "warn";

                const iconConfig: IconProps = {
                  color:
                    appointment.status !== "inProgress"
                      ? textStatusCard
                      : "green",
                  type: appointment.status === "inProgress" ? "live" : "clock",
                };

                return (
                  <Fragment key={appointment?.id}>
                    <CardsWithStatus
                      onPress={() => {
                        if (appointmentAvaliable) {
                          if (appointment?.patientRoom) {
                            Linking.openURL(appointment?.patientRoom);
                          } else {
                            setShowModal(true);
                          }
                        } else {
                          navigation.navigate("Detalhes da consulta", {
                            appointmentId: appointment?.id,
                            patientName: user.name || "",
                            shouldCount: isInsideDayRange,
                            appointmentDate: appointmentDate?.toISOString(),
                            appointmentStatus: appointment?.status,
                            appointmentLinkRoom: appointment?.patientRoom,
                            cancelReason: appointment?.cancelReason,
                            provider: appointment.provider,
                            plan: appointment.plan,
                            specialtyId: appointment?.specialtyId,
                            specialtyName: appointment?.specialtyId
                              ? specialtyIdToSpecialtyName[
                              appointment?.specialtyId
                              ]
                              : "",
                          });
                        }
                      }}
                      text={`${fullDate} às ${time}`}
                      subTitle={
                        appointmentAvaliable
                          ? {
                            style: textStatusCard,
                            textContent: subTitleText,
                          }
                          : {}
                      }
                      icon={iconConfig}
                      showTip={
                        appointmentAvaliable ||
                        appointment.status === "inProgress"
                      }
                      status={{ color, text }}
                      flag={
                        appointment.patient.cpf !== user.cpf ? "Dependente" : ""
                      }
                      specialtyName={
                        appointment?.specialtyId
                          ? specialtyIdToSpecialtyName[appointment.specialtyId]
                          : ""
                      }
                      compliment={
                        appointment.patientId !== user.id
                          ? appointment?.patient?.name
                          : ""
                      }
                    />
                  </Fragment>
                );
              })
          ) : (
            <View>
              <NoDataMessage text="Você não possui nenhum histórico." />
            </View>
          )}
        </View>
        <ConfirmationModal
          title="Link da consulta indisponível"
          description="Ocorreu algum problema com o link da consulta. Verifique a hora do agendamento."
          dontShowCancelButton
          confirmButton={{
            text: "Ok",
            color: "blue",
            onClick: async () => {
              setShowModal(false);
            },
          }}
          isModalOpen={showModal}
          setIsModalOpen={setShowModal}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

export { History };
