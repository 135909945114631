import { StyleProp, Text, View, ViewStyle } from "react-native";

import {
  AntDesign,
  FontAwesome,
  FontAwesome5,
  Foundation,
  MaterialIcons,
  Octicons,
} from "@expo/vector-icons";
import { styles } from "./styles";

type IconType =
  | "user"
  | "phone"
  | "cpf"
  | "registration"
  | "email"
  | "birthdate"
  | "masculine"
  | "feminine";

interface TextWithIconProps {
  iconType: IconType;
  keyText: string;
  textValue: string;
  additionalStyles?: StyleProp<ViewStyle>;
}

const IconsList = {
  user: <AntDesign name="user" style={styles.icon} />,
  phone: <FontAwesome name="phone" style={styles.icon} />,
  cpf: <FontAwesome5 name="id-card" style={styles.icon} />,
  registration: <Octicons name="number" style={styles.icon} />,
  email: <MaterialIcons name="email" style={styles.icon} />,
  birthdate: <FontAwesome5 name="calendar-alt" style={styles.icon} />,
  masculine: <Foundation name="male-symbol" style={styles.icon} />,
  feminine: <Foundation name="female-symbol" style={styles.icon} />,
};

const TextWithIcon = (props: TextWithIconProps) => {
  const { iconType, keyText, textValue, additionalStyles } = props;

  return (
    <View style={[styles.container, additionalStyles]}>
      {IconsList[iconType]}
      <Text style={styles.keyText}>{keyText}:</Text>
      <Text style={styles.valueText}>{textValue}</Text>
    </View>
  );
};

export { TextWithIcon };
