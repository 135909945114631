import { useEffect, useState } from "react";

import {
  Linking,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  Image,
} from "react-native";

import {
  DocumentData,
  QuerySnapshot,
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { differenceInMinutes, isBefore, parseISO, subHours } from "date-fns";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

import { GoBack } from "../../Buttons/GoBack";
import ConfirmationModal from "../../Modals/ConfirmationModal";

import { firestore } from "../../../config/firebase";

import { useAuth } from "../../../hooks/useAuth";

import { ParamListBase } from "@react-navigation/native";
import { scheduleOnDemandDoc24 } from "../../../services/api/doc24/scheduleOnDemandDoc24";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";
import { DefaultButton } from "../../Buttons/DefaultButton";
import { useCompany } from "../../../hooks/useCompany";

interface ErrorMessageProps {
  title: string;
  description: string;
}

interface Doc24ScheduleOnDemandPageProps {
  navigation: NativeStackNavigationProp<ParamListBase, string, undefined>;
  setIsOnFirstStep: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSpecialty: any;
}

const Doc24ScheduleOnDemandPage = (props: Doc24ScheduleOnDemandPageProps) => {
  const { navigation, setIsOnFirstStep, selectedSpecialty } = props;

  const { user } = useAuth();
  const { company } = useCompany();

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps>();
  const [appointmentURL, setAppointmentURL] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const isWebDevice = Platform.OS === "web";

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  const findNextAppointments = (appointments: QuerySnapshot<DocumentData>) => {
    const now = new Date();

    const nextAppointments = appointments.docs
      .map((appointment) => appointment.data())
      .filter((appointment) => {
        const minutesDifference = differenceInMinutes(
          parseISO(appointment.appointmentTime),
          now
        );
        const isInsideDayRange = isBefore(
          subHours(parseISO(appointment.appointmentTime), 24),
          now
        );

        if (
          minutesDifference < 60 &&
          minutesDifference >= -20 &&
          isInsideDayRange
        ) {
          return appointment;
        }
      });

    return nextAppointments;
  };

  const handleSchedule = async () => {
    setLoading(true);

    if (!user.id) {
      return;
    }
    const response: any = await scheduleOnDemandDoc24({
      email: user.email,
      gender: user.gender,
      identificationType: 9,
      identificationValue: user.cpf,
      name: user.name,
      lastName:
        user.lastName ||
        (user.name.includes(" ") &&
          user.name.substring(user.name.indexOf(" ")).trim()) ||
        " ",
      patientId: user.id || "",
      plan: user.plan,
      provider: user.provider,
      specialtyId: selectedSpecialty.specialtyId,
      patient: user,
    });
    setAppointmentURL(response.data?.data?.patientRoom || "");

    if (response.error) {
      setErrorMessage({
        title: "Falha no atendimento...",
        description: response.message,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    const collectionRef = collection(firestore, "appointments");
    const appointmentsQuery = query(
      collectionRef,
      orderBy("appointmentAt", "desc"),
      where("patient.cpf", "==", user.cpf),
      where("status", "==", "open")
    );

    const unsubscribeAppointments = onSnapshot(
      appointmentsQuery,
      (querySnapShot) => {
        const nextAppointment = findNextAppointments(querySnapShot)[0];

        if (nextAppointment?.id) {
          navigation.navigate("Detalhes da consulta", {
            appointmentId: nextAppointment?.id,
            patientName: user?.name || "",
            shouldCount: true,
            appointmentDate: nextAppointment?.appointmentTime,
            appointmentStatus: nextAppointment?.status,
            appointmentLinkRoom: nextAppointment?.patientRoom,
          });
        }
      }
    );

    return () => {
      unsubscribeAppointments();
    };
  }, []);

  if (!loading && appointmentURL) {
    setTimeout(() => {
      if (!isWebDevice) {
        Linking.openURL(appointmentURL);
        navigation.navigate("Home");
        setAppointmentURL("");
      }
    }, 2500);
  }

  return (
    <SafeAreaView style={styles.container}>
      {!loading && !errorMessage?.title && !appointmentURL && (
        <>
          <Text style={styles.specitaltyText}>
            {selectedSpecialty.specialty}
          </Text>
          <TouchableOpacity
            style={[
              styles.startButton,
              {
                backgroundColor: colors.primaryColor,
              },
            ]}
            activeOpacity={0.7}
            onPress={() => setShowConfirmationModal(true)}
          >
            <Text style={styles.startButtonText}>Iniciar</Text>
          </TouchableOpacity>
          <GoBack
            onPress={() => setIsOnFirstStep(true)}
            additionalStyles={{ marginTop: "30%" }}
          />
        </>
      )}

      {loading && (
        <>
          <Image
            source={require("../../../../assets/gifs/searching-doctors.gif")}
            style={styles.searchingDoctorsWeb}
          />
          <Text style={[styles.loadingMessage, { color: colors.primaryColor }]}>
            Aguarde a sua vez...
          </Text>
        </>
      )}
      {!loading && appointmentURL && (
        <>
          <Image
            source={require("../../../../assets/gifs/congratulations.gif")}
          />
          <Text style={styles.congratsText}>Chegou a sua vez!!!</Text>
          <DefaultButton
            onPress={() => {
              Linking.openURL(appointmentURL);
            }}
            text="Acessar atendimento"
            isLoading={!appointmentURL}
          />
        </>
      )}

      {!loading && errorMessage?.title && errorMessage.description && (
        <>
          <Text style={styles.errorTitle}>{errorMessage.title}</Text>
          <Text style={styles.errorDescription}>
            {errorMessage.description}
          </Text>
          <GoBack
            onPress={() => setIsOnFirstStep(true)}
            additionalStyles={{ marginTop: "30%" }}
          />
        </>
      )}
      <ConfirmationModal
        title="Atendimento!"
        description="Deseja iniciar o atendimento?"
        confirmButton={{
          text: "Sim",
          color: "blue",
          onClick: async () => {
            setShowConfirmationModal(false);
            await handleSchedule();
          },
        }}
        cancelButton={{
          text: "Não",
          onClick: () => setShowConfirmationModal(false),
        }}
        isModalOpen={showConfirmationModal}
        setIsModalOpen={setShowConfirmationModal}
      />
    </SafeAreaView>
  );
};

export { Doc24ScheduleOnDemandPage };
