import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    width: RFValue(144),
    height: "auto",
    borderWidth: 1,
    borderRadius: 12,
    paddingVertical: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 10,
  },
  icon: {
    width: 50,
    height: 50,
    fontSize: RFValue(40),
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  specialtyText: {
    fontSize: 14,
    fontFamily: fonts.medium,
    textAlign: "center",
    marginTop: 19,
  },
});

export { styles };
