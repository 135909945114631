import { NativeStackHeaderProps } from "@react-navigation/native-stack";

import { useAuth } from "../../hooks/useAuth";

import { BasicLoading } from "../../components/Loadings/BasicLoading";
import { Doc24ScheduleOnDemandPageManager } from "../../components/Pages/Doc24ScheduleOnDemandPageManager";
import { DrTisScheduleOnDemandPage } from "../../components/Pages/DrTisScheduleOnDemandPage";
import { ProviderUnavailable } from "../../components/Pages/ProviderUnavailable";

const ScheduleOnDemand = ({ navigation }: NativeStackHeaderProps) => {
  const { user } = useAuth();

  if (!user) {
    return <BasicLoading />;
  }

  if (user.provider === "dr_tis") {
    return <DrTisScheduleOnDemandPage navigation={navigation} />;
  }

  if (user.provider === "doc24") {
    return <Doc24ScheduleOnDemandPageManager navigation={navigation} />;
  }

  return <ProviderUnavailable />;
};

export { ScheduleOnDemand };
