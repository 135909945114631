import { Feather } from "@expo/vector-icons";
import { Image, TouchableOpacity, View } from "react-native";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useCompany } from "../../../hooks/useCompany";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";

interface MainHeaderProps {
  onClickMenu?: () => void;
}

const MainHeader = (props: MainHeaderProps) => {
  const { onClickMenu } = props;

  const { company } = useCompany();
  const colors = getColor({ company: company ? company : companyDefaultTheme });

  return (
    <View style={[styles.header, { backgroundColor: colors.primaryColor }]}>
      <TouchableOpacity
        style={onClickMenu ? styles.button : styles.none}
        activeOpacity={0.7}
        onPress={onClickMenu}
        disabled={!onClickMenu}
      >
        <Feather name="menu" size={24} style={{ color: colors.primaryColor }} />
      </TouchableOpacity>

      <Image
        style={styles.image}
        source={
          company.logo
            ? { uri: company.logo }
            : require("../../../../assets/logo.png")
        }
      />

      {/* <Avatar uri={getAssetsSources().profileImage} /> */}
    </View>
  );
};

export { MainHeader };
