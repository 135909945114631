import { Fragment, useEffect, useState } from "react";

import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StatusBar,
  Text,
  View,
} from "react-native";

import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { DefaultButton } from "../../Buttons/DefaultButton";
import { AppointmentInfoCard } from "../../Cards/AppointmentInfoCard";
import { HeaderWithGoBack } from "../../Headers/HeaderWithGoBack";

import Toast from "react-native-root-toast";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import ModalBottomSheet from "../../Modals/ModalBottomSheet";

import { useAuth } from "../../../hooks/useAuth";

import { getAvaiableHours } from "../../../services/drTis/getAvaibleHours";

import { getColor } from "../../../styles/colors";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

import SafeAreaView from "react-native-safe-area-view";
import { styles } from "./styles";

import { ParamListBase } from "@react-navigation/native";

import { CreateAppointmentProps } from "../../../models/api/appointment";
import { appointmentDataProps } from "../../../models/appointmentDataProps";
import { DrTisDoctorProps } from "../../../models/drTis/drTisDoctorProps";
import { UserProps } from "../../../models/userProps";
import { createAppointmentApi } from "../../../services/api/appointment/createAppointment";
import { generateDrTisAvailableHours } from "../../../utils/date/generateDrTisAvailableHours";
import { SelectAppointmentCard } from "../../Cards/SelectAppointmentCard";
import { SelectUserDropdown } from "../../Dropdowns/SelectUserDropdown";
import { BasicLoading } from "../../Loadings/BasicLoading";
import { ThreeDotsLoading } from "../../Loadings/ThreeDotsLoading";
import { getAvailableHoursApi } from "../../../services/api/appointment/getAvailableHours";
import { AvailableHoursDrTisPropsReturn } from "../../../models/drTis/availableHoursProps";
import { useCompany } from "../../../hooks/useCompany";

interface DrTisScheduleAppointmentPageProps {
  navigation: NativeStackNavigationProp<ParamListBase, string, undefined>;
}

interface AvailableAppointmentProps {
  doctor: string;
  appointmentHours: string[];
}

function serializeAppointmentHours(data: AvailableHoursDrTisPropsReturn) {
  return {
    ...data,
    rows: data?.rows?.map((row: any) => {
      let recurrentDays = row.recurrentDays;
      recurrentDays = { ...recurrentDays, fri: recurrentDays.fry };
      delete recurrentDays.fry;
      return { ...row, recurrentDays };
    }),
  };
}

const DrTisScheduleAppointmentPage = ({
  navigation,
}: DrTisScheduleAppointmentPageProps) => {
  const { user } = useAuth();
  const { company } = useCompany();

  const [appointmentData, setAppointmentData] = useState<appointmentDataProps>(
    {} as appointmentDataProps
  );

  const [showBottomSheetModal, setShowBottomSheetModal] =
    useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const [appointmentDoctor, setAppointmentDoctor] = useState<DrTisDoctorProps>(
    {} as DrTisDoctorProps
  );

  const [selectedUser, setSelectedUser] = useState<UserProps>(user);

  const [availableHours, setAvailableHours] =
    useState<AvailableAppointmentProps>({} as AvailableAppointmentProps);

  const [isFetchingAvailableHours, setIsFetchingAvailableHours] =
    useState<boolean>(false);
  const [isPaginating, setIsPaginating] = useState<boolean>(false);

  const colors = getColor({ company: company ? company : companyDefaultTheme });
  useEffect(() => {
    const handleGetAvaibleHours = async () => {
      setIsFetchingAvailableHours(true);
      const response = await getAvailableHoursApi({
        provider: user.provider,
        plan: user.plan,
      });

      const data = response.data as AvailableHoursDrTisPropsReturn;
      const serializedHours = serializeAppointmentHours(data);

      if (response.errorCode || response.errorMessage) {
        Toast.show("Ocorreu um erro ao buscar os horários disponíveis.", {
          duration: Toast.durations.LONG,
        });
        return;
      }

      const medicalScheduleGleebem = serializedHours?.rows?.find(
        (row: any) => row.queueId === process.env.QUEUE_ID
      );

      const appointmentHours = generateDrTisAvailableHours({
        recurrentDays: serializedHours?.rows[0]?.recurrentDays,
        startDate: new Date().toISOString(),
        daysRange: 4,
      });

      setAvailableHours({
        doctor: serializedHours?.rows[0]?.doctor.name,
        appointmentHours,
      });

      setAppointmentDoctor(
        medicalScheduleGleebem?.doctor || ({} as DrTisDoctorProps)
      );
      setIsFetchingAvailableHours(false);
    };

    handleGetAvaibleHours();
  }, []);

  async function handleSchedule() {
    const appointmentConfig: CreateAppointmentProps = {
      shouldWait: true,
      appointmentTime: appointmentData.ISODate,
      provider: user.provider,
      plan: user.plan,
      patient: selectedUser,
      patientId: selectedUser.id,
      doctor: appointmentDoctor,
      queueId: process.env.QUEUE_ID,
    };

    const createAppointmentResponse: any = await createAppointmentApi(
      appointmentConfig
    );

    if (createAppointmentResponse.statusCode === 500) {
      setAppointmentData({} as appointmentDataProps);
      setShowErrorModal(true);
      return;
    }

    setSelectedUser(user);

    if (!!createAppointmentResponse?.error) {
      setAppointmentData({} as appointmentDataProps);
      Toast.show("Não foi possível salvar seu atendimento", {
        duration: Toast.durations.LONG,
      });
      return;
    }

    return setShowBottomSheetModal(true);
  }

  async function handleFetchAvailableHours() {
    const response = await getAvailableHoursApi({
      provider: user.provider,
      plan: user.plan,
    });
    const data = response.data as AvailableHoursDrTisPropsReturn;
    const serializedHours = serializeAppointmentHours(data);
    const appointmentHours = generateDrTisAvailableHours({
      recurrentDays: serializedHours?.rows[0]?.recurrentDays,
      startDate:
        availableHours.appointmentHours[
          availableHours.appointmentHours.length - 1
        ],
      daysRange: 2,
      excludeFirst: true,
    });

    setAvailableHours((oldValues) => {
      return {
        doctor: serializedHours?.rows[0]?.doctor?.name,
        appointmentHours: [...oldValues.appointmentHours, ...appointmentHours],
      };
    });
  }

  async function handleScroll(event: NativeSyntheticEvent<NativeScrollEvent>) {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;

    const isOnEnd =
      layoutMeasurement.height + contentOffset.y + 5 >= contentSize.height;

    if (isOnEnd && !isFetchingAvailableHours) {
      setIsPaginating(true);
      await handleFetchAvailableHours();
      setIsPaginating(false);
    }
  }

  return (
    <SafeAreaView style={styles.container} forceInset={{ top: "always" }}>
      <StatusBar backgroundColor={colors.primaryColor} translucent={true} />
      <HeaderWithGoBack
        title="Agenda"
        description="Atendimento virtual"
        goBackFunction={() => navigation.goBack()}
      />
      {!isFetchingAvailableHours &&
        availableHours.appointmentHours?.length === 0 && (
          <Text style={styles.noAvailableHours}>
            Nenhum horário disponível!
          </Text>
        )}
      <ScrollView
        style={styles.scrollView}
        onScroll={handleScroll}
        scrollEventThrottle={16}
      >
        {isFetchingAvailableHours ? (
          <BasicLoading />
        ) : (
          availableHours?.appointmentHours?.map((availableHour) => {
            return (
              <SelectAppointmentCard
                key={`${availableHour}_${Math.random()}`}
                appointmentTime={availableHour}
                isActive={appointmentData.ISODate === availableHour}
                onPress={() => {
                  setAppointmentData({
                    ISODate: availableHour,
                  });
                }}
                doctorName={availableHours.doctor}
              />
            );
          })
        )}
        {!isFetchingAvailableHours && isPaginating && <ThreeDotsLoading />}

        <ModalBottomSheet
          title={"Agendamento efetuado com sucesso!"}
          onRequestClose={() => {
            setAppointmentData({} as appointmentDataProps);
            setShowBottomSheetModal(false);
          }}
          visible={showBottomSheetModal}
        >
          <View style={styles.modalContainer}>
            <Text style={styles.modalTitle}>
              Agendamento efetuado com sucesso!
            </Text>

            <AppointmentInfoCard
              patientName={user.name || ""}
              appointmentDate={appointmentData.ISODate}
              border={true}
            />
            <DefaultButton
              onPress={() => {
                setAppointmentData({} as appointmentDataProps);
                setShowBottomSheetModal(false);
                navigation.navigate("Home");
              }}
              text={"Ir para o início"}
              buttonHeight={35}
              buttonWidth={256}
            />
          </View>
        </ModalBottomSheet>

        <ConfirmationModal
          isModalOpen={showErrorModal}
          setIsModalOpen={setShowErrorModal}
          title="Operação falhou"
          description="Não foi possível agendar a sua consulta. Tente novamente mais tarde!"
          confirmButton={{
            color: "grey",
            onClick: () => {
              setShowErrorModal(false);
              navigation.navigate("Home");
            },
            text: "Ok",
          }}
          dontShowCancelButton
        />

        <ConfirmationModal
          title="Agendar consulta!"
          description="Deseja agendar essa consulta consulta?"
          cancelButton={{
            onClick: () => setShowConfirmationModal(false),
            text: "Não",
          }}
          confirmButton={{
            text: "Sim",
            color: "blue",
            onClick: async () => {
              setShowConfirmationModal(false);
              await handleSchedule();
            },
          }}
          isModalOpen={showConfirmationModal}
          setIsModalOpen={setShowConfirmationModal}
        >
          <AppointmentInfoCard
            patientName={selectedUser.name || ""}
            appointmentDate={appointmentData.ISODate}
            border={true}
          />
          {!!user.dependents?.length && (
            <SelectUserDropdown
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              users={[user, ...(user?.dependents || [])]}
            />
          )}
        </ConfirmationModal>
      </ScrollView>
      <View style={styles.buttonWrapper}>
        <DefaultButton
          text="Agendar Consulta"
          disabled={!appointmentData.ISODate}
          onPress={() => setShowConfirmationModal(true)}
          additionalStyles={{
            alignSelf: "center",
          }}
        />
      </View>
    </SafeAreaView>
  );
};

export { DrTisScheduleAppointmentPage };
