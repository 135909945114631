import axios from "axios";
import { DeactivateAppointmentProps } from "../../../models/api/appointment";

async function deactivateAppointmentApi(props: DeactivateAppointmentProps) {
  try {
    const { id, plan, provider, specialtyId } = props;
    const response = await axios.patch(
      `${process.env.GLEEBEM_API}/appointment/deactivate/${id}`,
      { plan, provider, specialtyId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.GLEEBEM_ACCESS_KEY,
        },
      }
    );

    return response;
  } catch (error: any) {
    return {
      message:
        "Não foi possível desativar o atendimento, tente novamente mais tarde!",
      statusCode: error.status,
      error: error.response,
    };
  }
}

export { deactivateAppointmentApi };
