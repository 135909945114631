import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  cardWrapper: {
    display: "flex",
    height: "auto",
    backgroundColor: colors.white,
    paddingHorizontal: 19,
    paddingVertical: 14,
    borderRadius: 20,
    flexDirection: "row",
    alignItems: "center",
    margin: 12,
  },
  icon: {
    height: 30,
    width: 30,

    marginRight: 19,
  },
  text: {
    fontSize: RFValue(16),
    fontFamily: fonts.bold,
    color: colors.text,
  },
});

export { styles };
