import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";

import { BasicLoading } from "../components/Loadings/BasicLoading";
import { useAuth } from "../hooks/useAuth";
import { getCompanyById } from "../services/firestore/company/getCompanyById";
import { getCompanyByKey } from "../services/firestore/company/getCompanyByKey";
import { getSubdomainName } from "../utils/url/getSubdomainName";

interface MedicalNumberProps {
  stateAcronym: string;
  phone: string;
}
export interface CompanyProps {
  id: string;
  name: string;
  contactNumber: string;
  medicalPhone: MedicalNumberProps[];
  logo: string;
  banner: string;
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  website: string;
}

interface CompanyThemeProps {
  name: string;
  logo: string;
  website?: string;
  company: CompanyProps;
  setCompany: React.Dispatch<React.SetStateAction<CompanyProps>>;
}

interface CompanyThemeComponentProps {
  children: ReactNode;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompanyThemeContext = createContext({} as CompanyThemeProps);

const CompanyThemeComponent = (props: CompanyThemeComponentProps) => {
  const { children, loading, setLoading } = props;

  const { user } = useAuth();

  const [company, setCompany] = useState<CompanyProps>({} as CompanyProps);

  useEffect(() => {
    const handleGetCompany = async () => {
      const companyKey = window ? getSubdomainName(window.location?.href) : "";
      const companyId = user.companyId;
      const searchTerm = companyKey || companyId || "gleebem";

      const fetchCompanyFunction = !!companyKey
        ? getCompanyByKey
        : getCompanyById;

      const { data } = await fetchCompanyFunction(searchTerm);

      setCompany({
        id: data?.id,
        contactNumber: data?.contactNumber,
        logo: data?.logo,
        name: data?.name,
        medicalPhone: data?.medicalPhone,
        primaryColor: data?.primaryColor,
        secondaryColor: data?.secondaryColor,
        tertiaryColor: data?.tertiaryColor,
        website: data?.website,
        banner: data?.banner,
      });
      setLoading(false);
    };
    handleGetCompany();
  }, [user]);

  const companyTheme: CompanyThemeProps = useMemo(
    () => ({
      company,
      name: company.name,
      logo: company.logo,
      banner: company?.banner,
      website: company?.website,
      setCompany,
    }),
    [company]
  );

  return (
    <CompanyThemeContext.Provider value={companyTheme}>
      {children}
      {loading && <BasicLoading isFullScreen />}
    </CompanyThemeContext.Provider>
  );
};

export { CompanyThemeComponent, CompanyThemeContext };
