import {
  addDays,
  addMinutes,
  format,
  formatISO,
  isAfter,
  isBefore,
  parse,
  parseISO,
  setMinutes,
} from "date-fns";
import { RecurrentDaysProps } from "../../models/drTis/availableHoursProps";

interface GenerateDrTisAvailabeHoursProps {
  recurrentDays: RecurrentDaysProps;
  startDate: string;
  daysRange: number;
  excludeFirst?: boolean;
}

interface GenerateISOListProps {
  startHour: string;
  endHour: string;
  date: Date;
  hoursList: string[];
}

function generateDrTisAvailableHours(
  props: GenerateDrTisAvailabeHoursProps
): string[] {
  const { recurrentDays, startDate, daysRange, excludeFirst } = props;

  const availableHours: string[] = [];

  let currentDate = parseISO(startDate);
  if (excludeFirst) {
    currentDate = addDays(currentDate, 1);
  }

  for (let i = 0; i <= daysRange; i++) {
    const currentWeekday = format(currentDate, "EEE").toLowerCase() as
      | "mon"
      | "tue"
      | "wed"
      | "thu"
      | "fri"
      | "sat"
      | "sun";

    recurrentDays[currentWeekday].map((availableHour) => {
      generateISOList({
        startHour: availableHour.from,
        endHour: availableHour.to,
        date: currentDate,
        hoursList: availableHours,
      });
    });

    currentDate = addDays(currentDate, 1);
  }

  return availableHours.filter((availableHour) => {
    const now = new Date();

    return isAfter(parseISO(availableHour), now);
  });
}

function generateISOList(props: GenerateISOListProps): string[] {
  const { date, endHour, startHour, hoursList } = props;

  let currentTime = parse(startHour, "HH:mm", date);
  const endTime = parse(endHour, "HH:mm", date);

  hoursList.push(formatISO(currentTime));

  while (isBefore(currentTime, endTime)) {
    currentTime = addMinutes(currentTime, 15);

    hoursList.push(formatISO(currentTime));
  }

  return hoursList;
}

export { generateDrTisAvailableHours, generateISOList };
