import {
  DocumentData,
  collection,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../../config/firebase";
import { PropsReturn } from "../../../models/core.props-return";

interface getUserProps {
  cpf: string;
  registration: string;
}

interface GetUserPropsReturn extends PropsReturn {
  data: DocumentData;
}

async function getUser(props: getUserProps): Promise<GetUserPropsReturn> {
  const { cpf, registration } = props;

  try {
    const userQuery = query(
      collection(firestore, "users"),
      where("cpf", "==", cpf),
      where("registration", "==", registration),
      where("isActive", "==", true),
      limit(1)
    );
    const response = await getDocs(userQuery);

    if (response.docs.length) {
      return {
        message: "Usuário encontrado com sucesso.",
        data: response.docs[0].data(),
      };
    }

    return {
      message: "Não foi possível encontrar o usuário!",
      errorCode: "404",
      data: {},
    };
  } catch (error: any) {
    return {
      message: "Não foi possível encontrar o usuário!",
      errorCode: error.code,
      errorMessage: error.message,
      data: {},
    };
  }
}

export { getUser };
