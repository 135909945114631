import { View, Image } from "react-native";
import { styles } from "./styles";

interface AvatarProps {
  uri: string;
}

const Avatar = (props: AvatarProps) => {
  const { uri } = props;
  return (
    <View style={styles.imageWrapper}>
      <Image source={{ uri }} style={styles.image} />
    </View>
  );
};

export { Avatar };
