import { Text, View } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";

import { DefaultButton } from "../../components/Buttons/DefaultButton";
import { InputWithLabel } from "../../components/Inputs/InputWithLabel";
import { styles } from "./styles";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";
import { RadioInput } from "../../components/Inputs/RadioInput";
import {
  PreRegistrationFormAction,
  PreRegistrationFormActionKind,
  PreRegistrationFormState,
} from "../../reducers/preRegistrations/preRegistrationFormReducer";
import { getColor } from "../../styles/colors";

import * as yup from "yup";
import { isBefore, isValid, parse } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Toast from "react-native-root-toast";
import { useEffect, useState } from "react";
import { ValidationInput } from "../../components/Inputs/ValidationInput";
import { getUserByEmail } from "../../services/firestore/user/getUserByEmail";

interface Step1Props extends NativeStackHeaderProps {
  setSelectedStep: React.Dispatch<React.SetStateAction<1 | 2 | 3>>;
  preRegistrationState: PreRegistrationFormState;
  dispatchPreRegistrationState: React.Dispatch<PreRegistrationFormAction>;
  isInvalidUser?: boolean;
}

interface Step1FormData {
  registration: string;
  email: string;
  birthdate: string;
  gender: "Masculino" | "Feminino";
}

const schema = yup.object().shape({
  registration: yup
    .string()
    .required("A matrícula é um campo obrigatório!")
    .min(6, "A matrícula deve conter ao menos 6 caracteres!"),
  email: yup
    .string()
    .required("O email é um campo obrigatório!")
    .email("Insira um email válido!")
    .test(
      "isDefaultEmail",
      "Não é possível utilizar o email padrão",
      (value) => {
        return !value.includes(process.env.TEMPORARY_EMAIL_DOMAIN);
      }
    )
    .test(
      "isEmailAvailable",
      "O email já está sendo utilizado!",
      async (value) => {
        const response = await getUserByEmail({ email: value });

        return response.data?.email !== value;
      }
    ),

  birthdate: yup
    .string()
    .required("Data de nascimento é um campo obrigatório")
    .min(10, "Preencha a data de nascimento")
    .test("isValidDate", "Insira uma data válida", (value) => {
      const parsedDate = parse(value, "dd/MM/yyyy", new Date());

      return isValid(parsedDate);
    })
    .test(
      "isNotInFuture",
      "A data de nascimento não pode estar no futuro!",
      (value) => {
        const parsedDate = parse(value, "dd/MM/yyyy", new Date());

        return isBefore(parsedDate, new Date());
      }
    )
    .test("isNotTooOld", "A idade máxima é 120 anos!", (value: any) => {
      const parsedDate = parse(value, "dd/MM/yyyy", new Date());
      const minDate = new Date();
      const maxAge = 120;
      minDate.setFullYear(minDate.getFullYear() - maxAge);

      return isBefore(minDate, parsedDate);
    }),
  gender: yup
    .string()
    .oneOf(["Masculino", "Feminino"])
    .required("O gênero é um campo obrigatório!"),
});

const Step1 = (props: Step1Props) => {
  const {
    navigation,
    setSelectedStep,
    dispatchPreRegistrationState,
    preRegistrationState,
    isInvalidUser,
  } = props;

  const colors = getColor({ company: companyDefaultTheme });

  const { control, setValue, getValues } = useForm<Step1FormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const registrationPlaceholder = preRegistrationState.registration;

  useEffect(() => {
    setValue("birthdate", preRegistrationState.birthdate);
    setValue(
      "registration",
      isInvalidUser ? preRegistrationState.registration : ""
    );
    setValue(
      "email",
      preRegistrationState.email.includes(process.env.TEMPORARY_EMAIL_DOMAIN)
        ? ""
        : preRegistrationState.email
    );

    if (!!preRegistrationState.gender) {
      setValue("gender", preRegistrationState.gender);
    }
  }, [preRegistrationState]);

  async function handleNextStep(props: Step1FormData) {
    try {
      await schema.validate(props, { abortEarly: false });

      dispatchPreRegistrationState({
        type: PreRegistrationFormActionKind.UPDATE_REGISTRATION,
        registration: props.registration,
      });
      dispatchPreRegistrationState({
        type: PreRegistrationFormActionKind.UPDATE_EMAIL,
        email: props.email,
      });
      dispatchPreRegistrationState({
        type: PreRegistrationFormActionKind.UPDATE_BIRTHDATE,
        birthdate: props.birthdate,
      });
      dispatchPreRegistrationState({
        type: PreRegistrationFormActionKind.UPDATE_GENDER,
        gender: props.gender,
      });

      setSelectedStep(2);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return Toast.show("Preencha os dados corretamente para prosseguir!", {
          duration: Toast.durations.LONG,
        });
      }

      Toast.show(
        "Algo não deu certo em seu pré-registro. Tente mais tarde...",
        { duration: Toast.durations.LONG }
      );
    }
  }

  return (
    <>
      <View style={styles.registrationDataContainer}>
        <View style={styles.registrationDataWrapper}>
          <View style={styles.header}>
            <View style={styles.stepIndicatorWrapper}>
              <Text style={styles.currentStepText}>1</Text>
              <Text style={styles.totalStepsText}>/3</Text>
            </View>
            <View style={styles.stepTitleWrapper}>
              <MaterialIcons
                name="app-registration"
                style={styles.registrationIcon}
              />
              <Text style={styles.headerText}>Preencha seus dados</Text>
            </View>
          </View>
          <Text style={styles.description}>
            Redefina a sua matrícula e preencha os dados a seguir:
          </Text>

          <View>
            <ValidationInput
              control={control}
              name="registration"
              label="Matrícula"
              additionalLabelStyles={{ marginTop: 17 }}
              keyboardType="numeric"
              placeholder={registrationPlaceholder}
            />

            <ValidationInput
              control={control}
              name="email"
              label="Email"
              additionalLabelStyles={{ marginTop: 10 }}
              inputMode="email"
              placeholder="email@exemplo.com"
            />

            <ValidationInput
              control={control}
              name="birthdate"
              label="Data de nascimento"
              additionalLabelStyles={{ marginTop: 10 }}
              maskType="datetime"
              inputType={"with-mask"}
              placeholder={"__/__/____"}
              maxLength={10}
            />

            <View>
              <Text style={styles.genderLabel}>Gênero</Text>

              <View style={styles.inputsRow}>
                <Controller
                  control={control}
                  name="gender"
                  render={({ field }) => (
                    <RadioInput
                      label={"Masculino"}
                      value={"Masculino"}
                      onPress={() => {
                        setValue("gender", "Masculino");
                      }}
                      selectedValue={field.value}
                      additionalContainerStyles={{ marginRight: 10 }}
                    />
                  )}
                />
                <Controller
                  name={"gender"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <RadioInput
                      label={"Feminino"}
                      value={"Feminino"}
                      onPress={() => {
                        setValue("gender", "Feminino");
                      }}
                      selectedValue={field.value}
                    />
                  )}
                />
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.buttonWrapper}>
        <DefaultButton
          text={"Voltar"}
          onPress={async () => {
            navigation.navigate("Login");
          }}
          additionalStyles={{
            marginTop: 20,
            backgroundColor: colors.transparent,
            borderWidth: 2,
            borderColor: colors.primaryColor,
          }}
          buttonWidth={130}
          textColor={colors.primaryColor}
        />
        <DefaultButton
          text={"Continuar"}
          onPress={async () => await handleNextStep(getValues())}
          additionalStyles={{ marginTop: 20 }}
          buttonWidth={130}
        />
      </View>
    </>
  );
};

export { Step1 };
