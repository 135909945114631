import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Text, TouchableOpacity, View } from "react-native";
import { getColor } from "../../../styles/colors";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { styles } from "./styles";
import { useAuth } from "../../../hooks/useAuth";
import { useCompany } from "../../../hooks/useCompany";

interface IconProps {
  color: "warn" | "danger" | "green";
  type: "clock" | "live";
}

interface CardsWithStatusProps {
  text: string;
  icon?: IconProps;
  subTitle?: {
    style?: "warn" | "danger";
    textContent?: string;
  };
  status: {
    text: string;
    color: string;
  };
  flag?: string;
  compliment?: string;
  onPress: () => void;
  showTip: boolean;
  specialtyName?: string;
}

const CardsWithStatus = (props: CardsWithStatusProps) => {
  const { company } = useCompany();
  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });
  const {
    text,
    status,
    showTip,
    onPress,
    icon,
    subTitle,
    flag,
    compliment,
    specialtyName,
  } = props;

  const colorsSet = {
    danger: colors.redCancel,
    warn: colors.textYellow,
    green: colors.textGreen,
  };

  const iconsSet = {
    clock: (
      <MaterialCommunityIcons
        name="progress-clock"
        style={[
          styles.icon,
          {
            color: colorsSet[icon?.color || "warn"],
          },
        ]}
      />
    ),
    live: (
      <MaterialCommunityIcons
        name="antenna"
        style={[
          styles.icon,
          {
            color: colorsSet[icon?.color || "warn"],
          },
        ]}
      />
    ),
  };

  return (
    <TouchableOpacity
      style={[styles.cardWrapper, { borderBottomColor: colors.primaryColor }]}
      activeOpacity={0.7}
      onPress={onPress}
      key={Math.random()}
    >
      <View style={styles.statusWrapper}>
        <View style={styles.statusTextWrapper}>
          <Text
            style={[
              styles.statusTextCompliment,
              { color: colors.primaryColor },
            ]}
          >
            Teleconsulta
          </Text>
          <Text style={[styles.cardStatus, { color: status?.color }]}>
            {status.text}
          </Text>

          {!!flag && (
            <View
              style={[
                styles.flagWrapper,
                { backgroundColor: colors.secondaryColor },
              ]}
            >
              <Text style={styles.flagText}>{flag}</Text>
            </View>
          )}
        </View>
        {showTip && icon?.type && icon?.color && iconsSet[icon.type]}
      </View>
      {!!specialtyName && (
        <Text style={styles.specialtyName}>{specialtyName}</Text>
      )}
      <Text style={styles.cardText}>{text}</Text>
      {!!compliment && <Text style={styles.compliment}>{compliment}</Text>}
      {!!subTitle?.textContent && (
        <Text
          style={[
            styles.subTitle,
            subTitle.style === "danger" && { color: colors.textRed },
          ]}
        >
          {subTitle.textContent}
        </Text>
      )}
      {showTip && (
        <View
          style={[
            styles.joinRoomButton,
            { backgroundColor: colors.primaryColor },
          ]}
        >
          <Text style={styles.jointBtnText}>Entrar na sala</Text>
        </View>
      )}
    </TouchableOpacity>
  );
};

export { CardsWithStatus, IconProps };
