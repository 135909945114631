import axios from "axios";
import { Plans, Providers } from "../../../models/gleebem.core";
import { createQueryUrl } from "../../../utils/url/createQueryUrl";

interface getAvailableHoursApiProps {
  provider: Providers;
  plan: Plans;
  specialtyId?: number;
  queries?: {
    date?: string;
    daysAhead?: number;
    limit?: number;
    offset?: number;
  };
}

async function getAvailableHoursApi(props: getAvailableHoursApiProps) {
  try {
    const { provider, plan, specialtyId, queries } = props;
    let url = `${process.env.GLEEBEM_API}/appointment/find-available-hours/provider/${provider}`;

    if (!!queries) {
      url = createQueryUrl({ attributesList: queries, url });
    }

    const response: any = await axios.post(
      url,
      {
        specialtyId,
        plan,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.GLEEBEM_ACCESS_KEY,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    return {
      message: "Não foi possível encontrar horários de atendimentos",
      statusCode: error.status,
      error: error.response,
    };
  }
}

export { getAvailableHoursApi };
