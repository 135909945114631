import { useState } from "react";
import { StatusBar } from "react-native";
import SafeAreaView from "react-native-safe-area-view";

import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

import { ParamListBase } from "@react-navigation/native";
import { SpecialtyProps } from "../../../models/doc24/specialtyProps";
import { getColor } from "../../../styles/colors";
import { HeaderWithGoBack } from "../../Headers/HeaderWithGoBack";
import { Doc24ScheduleOnDemandPage } from "../Doc24ScheduleOnDemandPage";
import { SelectDoc24Specialties } from "../SelectDoc24Specialties";

import { useCompany } from "../../../hooks/useCompany";
import { styles } from "./styles";

interface Doc24ScheduleOnDemandPageManagerProps {
  navigation: NativeStackNavigationProp<ParamListBase, string, undefined>;
}

const Doc24ScheduleOnDemandPageManager = (
  props: Doc24ScheduleOnDemandPageManagerProps
) => {
  const { navigation } = props;

  const { company } = useCompany();
  const [isOnFirstStep, setIsOnFirstStep] = useState<boolean>(true);
  const [selectedSpecialty, setSelectedSpecialty] = useState<SpecialtyProps>({
    specilatyId: 4,
    specialty: "Clínica Geral",
  } as any);

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  return (
    <SafeAreaView style={styles.container} forceInset={{ top: "always" }}>
      <StatusBar backgroundColor={colors.primaryColor} translucent={true} />
      <HeaderWithGoBack
        title="Agenda"
        description="Atendimento instantâneo"
        goBackFunction={() => {
          if (isOnFirstStep) {
            navigation.goBack();
          } else {
            setIsOnFirstStep(true);
          }
        }}
      />
      {isOnFirstStep ? (
        <SelectDoc24Specialties
          selectedSpecialty={selectedSpecialty}
          setIsSpecialtySelected={setIsOnFirstStep}
          setSelectedSpecialty={setSelectedSpecialty}
          isRestricted
        />
      ) : (
        <Doc24ScheduleOnDemandPage
          navigation={navigation}
          selectedSpecialty={selectedSpecialty}
          setIsOnFirstStep={setIsOnFirstStep}
        />
      )}
    </SafeAreaView>
  );
};
export { Doc24ScheduleOnDemandPageManager };
