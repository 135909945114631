function validateObject(object: any) {
  const validatedDataToUpdate: any = {};

  Object.keys(object).map((currentKey) => {
    const currentValue = object[currentKey];

    if (
      typeof currentValue === "object" &&
      !!currentValue &&
      !Array.isArray(object)
    ) {
      validatedDataToUpdate[currentKey] = validateObject(currentValue);
    } else if (typeof currentValue === "boolean") {
      validatedDataToUpdate[currentKey] = currentValue;
    } else if (!!currentValue) {
      validatedDataToUpdate[currentKey] = currentValue;
    } else {
      validatedDataToUpdate[currentKey] = "";
    }
  });

  return validatedDataToUpdate;
}

export { validateObject };
