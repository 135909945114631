import axios from "axios";
import { CreateAppointmentProps } from "../../../models/api/appointment";

async function createAppointmentApi(props: CreateAppointmentProps) {
  try {
    const response = await axios.post(
      `${process.env.GLEEBEM_API}/appointment/create`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.GLEEBEM_ACCESS_KEY,
        },
      }
    );

    return response;
  } catch (error: any) {
    return {
      message:
        "Não foi possível criar o atendimento, tente novamente mais tarde!",
      statusCode: error.status,
      error: error.response,
    };
  }
}

export { createAppointmentApi };
