function joinObjects(modelObject: any, objectToJoin: any) {
  let object: any = {};

  for (const key of Object.keys(modelObject)) {
    const currentValue = objectToJoin[key] || modelObject[key];

    object[key] = currentValue;
  }

  return object;
}

export { joinObjects };
