enum LoginFormActionKind {
  SET_CPF = "SET_CPF",
  SET_REGISTRATION = "SET_REGISTRATION",
  SET_INPUT_ACTIVE = "SET_INPUT_ACTIVE",
  CLEAR_INPUT_ACTIVE = "CLEAR_INPUT_ACTIVE",
  CLEAR_DATA = "CLEAR_DATA",
}

interface LoginFormAction {
  type: LoginFormActionKind;
  cpf?: string;
  registration?: string;
  inputActive?: "cpf" | "registration" | "";
}

export interface LoginFormState {
  cpf: string;
  registration: string;
  inputActive: "cpf" | "registration" | "";
}

const loginFormInitialState: LoginFormState = {
  cpf: "",
  inputActive: "",
  registration: "",
};

function loginFormReducer(
  state: LoginFormState,
  action: LoginFormAction
): LoginFormState {
  switch (action.type) {
    case "SET_CPF":
      return {
        ...state,
        cpf: action.cpf || "",
      };
    case "SET_REGISTRATION":
      return {
        ...state,
        registration: action.registration || "",
      };
    case "SET_INPUT_ACTIVE":
      return {
        ...state,
        inputActive: action.inputActive || "",
      };
    case "SET_INPUT_ACTIVE":
      return {
        ...state,
        inputActive: "",
      };
    case "CLEAR_DATA":
      return loginFormInitialState;
    default:
      return state;
  }
}

export { loginFormReducer, loginFormInitialState, LoginFormActionKind };
