import { StyleSheet, Dimensions } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
  },

  startButton: {
    height: RFValue(150),
    width: RFValue(150),
    marginTop: "30%",
    borderRadius: 200,
    padding: 20,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },

  startButtonText: {
    fontSize: RFValue(20),
    fontFamily: fonts.bold,
    color: colors.white,
    alignContent: "center",
    justifyContent: "center",
    margin: "auto",
    textAlign: "center",
  },

  loadingMessage: {
    fontSize: RFValue(15),
    fontFamily: fonts.medium,
    position: "absolute",
    bottom: "20%",
  },
  congratulations: {
    margin: 10,
    flex: 1,
  },
  congratsText: {
    fontSize: RFValue(17.5),
    fontFamily: fonts.bold,
  },
  joinMeetingButton: {
    padding: 10,
    borderRadius: 20,
    backgroundColor: colors.secondaryColor,
    justifyContent: "center",
    alignContent: "center",
  },
  buttonText: {
    fontSize: RFValue(14),
    fontFamily: fonts.bold,
    color: colors.white,
  },
  errorTitle: {
    fontSize: RFValue(16),
    fontFamily: fonts.bold,
    color: colors.text,
  },
  errorDescription: {
    fontSize: RFValue(14),
    fontFamily: fonts.medium,
    color: colors.text,
    marginTop: 10,
    textAlign: "center",
  },
});

export { styles };
