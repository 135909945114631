import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  messageContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    alignContent: "center",
    margin: "auto",
    textAlign: "center",
    marginTop: 10,
  },
  messageText: {
    fontFamily: fonts.light,
    fontSize: RFValue(20),
    textAlign: "center",
    margin: "auto",
    color: colors.text,
  },
});

export { styles };
