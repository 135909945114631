import AsyncStorage from "@react-native-async-storage/async-storage";

import { getUser } from "../../services/firestore/user/getUser";

import {
  AuthReducerAction,
  AuthReducerActionKind,
} from "../../reducers/context/auth";

import { DocumentData } from "firebase/firestore";
import { PropsReturn } from "../../models/core.props-return";
import { UserProps } from "../../models/userProps";
import { validateEmailAndBirthdate } from "../../utils/dataValidation/validateEmailAndBirthdate";

interface signUserInControllerProps {
  userData: {
    cpf: string;
    registration: string;
  };
  dispatchAuthenticationState: React.Dispatch<AuthReducerAction>;
  setUser: React.Dispatch<React.SetStateAction<UserProps>>;
}

interface SignUserInControllerPropsReturn extends PropsReturn {
  data: DocumentData;
}

async function signUserInController(
  props: signUserInControllerProps
): Promise<SignUserInControllerPropsReturn> {
  const { userData, dispatchAuthenticationState, setUser } = props;

  let userFound = (
    await getUser({ cpf: userData.cpf, registration: userData.registration })
  ).data;

  if (!!userFound?.email && !!Date.parse(userFound?.birthdate)) {
    const { isBirthdateValid, isEmailValid } = validateEmailAndBirthdate({
      birthdate: userFound.birthdate,
      email: userFound.email,
    });

    if (!isBirthdateValid || !isEmailValid) {
      return {
        errorCode: "403",
        errorMessage: "[ERROR][INVALID_DATA]: email/birthdate are invalid!",
        message: "Usuário não possui dados válidos!",
        data: userFound,
      };
    }
  }

  if (!!userFound?.cpf && !!userFound?.registration) {
    dispatchAuthenticationState({
      type: AuthReducerActionKind.SIGN_IN,
      userCPF: userFound?.cpf,
      userRegistration: userFound?.registration,
    });

    await AsyncStorage.setItem("RNAuth_userCPF", userData.cpf || "");
    await AsyncStorage.setItem(
      "RNAuth_userRegistration",
      userData.registration || ""
    );

    setUser({
      id: userFound.id,
      cpf: userFound.cpf,
      email: userFound.email,
      name: userFound.name,
      lastName: userFound.lastName || "",
      registration: userFound.registration,
      isCallEnabled: !!userFound?.isCallEnabled,
      gender: userFound.gender,
      phone: userFound.phone,
      plan: userFound.plan,
      provider: userFound.provider,
      companyId: userFound?.companyId,
      birthdate: userFound?.birthdate || "",
    });

    return { message: "Login realizado com sucesso!", data: userFound };
  }

  return {
    message: "Usuário não encontrado",
    errorCode: "404",
    errorMessage: "No data",
    data: {} as UserProps,
  };
}

export { signUserInController };
