import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../styles/colors";
import { fonts } from "../../styles/fonts";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  registrationDataContainer: {
    display: "flex",
    flexGrow: 1,
  },
  registrationDataWrapper: {
    width: "95%",
    height: "auto",
    paddingVertical: 18,
    paddingHorizontal: 21,
    marginHorizontal: 12,
    marginTop: 34,
    display: "flex",
    backgroundColor: colors.white,
    borderRadius: 20,
  },
  header: {
    width: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
  idIcon: {
    fontSize: RFValue(30),
    color: colors.primaryColor,
    margin: "auto",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  headerText: {
    fontSize: RFValue(20),
    fontFamily: fonts.medium,
    color: colors.text,
    textAlign: "center",
    marginLeft: 7,
    margin: "auto",
  },
  editButton: {
    display: "flex",
    width: 50,
    height: 50,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
    position: "absolute",
    marginTop: -11,
    marginRight: -8,
  },
  editButtonIcon: {
    fontSize: 18,
    color: colors.white,
  },
  buttonWrapper: {
    display: "flex",
    alignContent: "flex-end",
    justifyContent: "center",
    margin: "auto",
    flexDirection: "row",
    marginBottom: 20,
  },
  genderLabel: {
    fontSize: RFValue(12),
    fontFamily: fonts.bold,
    marginTop: 16,
    marginBottom: 6,
  },
  inputsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textError: {
    fontSize: RFValue(12),
    color: colors.redCancel,
    fontFamily: fonts.bold,
  },
});

export { styles };
