import {
  StyleProp,
  Text,
  TextInput,
  TextInputProps,
  TextStyle,
} from "react-native";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { getColor } from "../../../styles/colors";

import { useState } from "react";
import {
  TextInputMask,
  TextInputMaskOptionProp,
  TextInputMaskTypeProp,
} from "react-native-masked-text";
import { useCompany } from "../../../hooks/useCompany";

import { Control, Controller } from "react-hook-form";
import { InputWithLabel } from "../InputWithLabel";

interface ValidationInputProps extends TextInputProps {
  control: Control<any, any>;
  label: string;
  name: string;
  editable?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  inputType?: "default" | "with-mask";
  options?: TextInputMaskOptionProp;
  maskType?: TextInputMaskTypeProp;
  additionalInputStyles?: StyleProp<TextStyle>;
  additionalLabelStyles?: StyleProp<TextStyle>;
}

const ValidationInput = (props: ValidationInputProps) => {
  const {
    name,
    control,
    label,
    editable = true,
    defaultValue,
    inputType,
    disabled,
    options,
    maskType,
    additionalInputStyles,
    additionalLabelStyles,
  } = props;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <InputWithLabel
          {...props}
          inputType={inputType}
          label={label}
          onChangeText={field.onChange}
          onBlur={field.onBlur}
          value={field.value}
          editable={editable}
          disabled={disabled}
          defaultValue={defaultValue}
          error={fieldState.error?.message}
          options={options}
          maskType={maskType}
          additionalLabelStyles={additionalLabelStyles}
          additionalInputStyles={additionalInputStyles}
        />
      )}
    />
  );
};

export { ValidationInput };
