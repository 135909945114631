import { StyleProp, Text, View, ViewStyle } from "react-native";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { getColor } from "../../../styles/colors";

import { useCompany } from "../../../hooks/useCompany";
import { styles } from "./styles";
import { Entypo } from "@expo/vector-icons";

interface CheckboxProps {
  onPress: () => void;
  label: string;
  value: boolean;

  additionalContainerStyles?: StyleProp<ViewStyle>;
  disabled?: boolean;
}

const Checkbox = (props: CheckboxProps) => {
  const { onPress, label, value, disabled, additionalContainerStyles } = props;
  const { company } = useCompany();

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  return (
    <View
      style={[
        styles.container,
        additionalContainerStyles,
        { opacity: disabled ? 0.4 : 1 },
      ]}
      onTouchEnd={() => {
        if (disabled) {
          return;
        }
        onPress();
      }}
    >
      <View
        style={[
          styles.checkbox,
          {
            borderColor: colors.primaryColor,
            backgroundColor: value ? colors.primaryColor : colors.white,
          },
        ]}
      >
        {value && <Entypo name="check" style={styles.checkIcon} />}
      </View>
      <Text style={styles.label}>{label}</Text>
    </View>
  );
};

export { Checkbox };
