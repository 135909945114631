import axios from "axios";
import { Plans } from "../../../models/gleebem.core";
import { SpecialtyProps } from "../../../models/doc24/specialtyProps";

interface GetSpecialtiesDoc24Props {
  plan: Plans;
  isRestricted?: boolean;
}

interface GetSpecialtiesDoc24PropsReturn {
  specialties: SpecialtyProps[];
  timestamp: string;
  message: string;
  statusCode: number;
  error?: string;
}

async function getSpecialtiesDoc24(
  props: GetSpecialtiesDoc24Props
): Promise<GetSpecialtiesDoc24PropsReturn> {
  const { plan, isRestricted } = props;

  try {
    const response = await axios.get(
      `${process.env.GLEEBEM_API}/doc24/specialties?isRestricted=${isRestricted}&plan=${plan}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.GLEEBEM_ACCESS_KEY,
        },
      }
    );

    return {
      specialties: response?.data?.data?.specialties,
      message: response.data.message,
      timestamp: response?.data?.data?.timestamp,
      statusCode: response.data.statusCode,
    };
  } catch (error: any) {
    return {
      message:
        "Ocorreu um erro ao buscar as especialidades. Por favor, tente mais tarde!",
      statusCode: error.status,
      error: error.response,
      timestamp: "",
      specialties: [],
    };
  }
}

export { getSpecialtiesDoc24 };
