import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  activeContainer: {
    width: "95%",
    alignSelf: "center",
    paddingHorizontal: 31,
    paddingVertical: 23,
    borderRadius: 20,
    backgroundColor: colors.white,
    marginBottom: 13,
    borderWidth: 2,
  },
  container: {
    width: "95%",
    alignSelf: "center",
    paddingHorizontal: 31,
    paddingVertical: 23,
    borderRadius: 20,
    backgroundColor: colors.white,
    marginBottom: 13,
  },
  doctorContainer: {
    flexDirection: "row",
  },
  timeContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  timeWrapper: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 12,
  },
  icon: {
    fontSize: RFValue(15),
    marginRight: 5,
  },
  text: {
    fontSize: RFValue(12),
    color: colors.text,
    fontFamily: fonts.light,
  },
  highlightText: {
    fontSize: RFValue(12),
    fontFamily: fonts.bold,
  },
});

export { styles };
