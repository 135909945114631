import { createStackNavigator } from "@react-navigation/stack";

import { FunctionComponent } from "react";
import { Login } from "../pages/Login";
import { ForgetRegistration } from "../pages/ForgetRegistration";
import { RegistrationSent } from "../pages/RegistrationSent";
import { FillPreRegistration } from "../pages/FillPreRegistration";

const Stack = createStackNavigator();

const AuthRoutes = () => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="Login"
    >
      <Stack.Screen name="Login" component={Login as FunctionComponent} />
      <Stack.Screen
        name="pre_registration"
        component={FillPreRegistration as FunctionComponent}
        // options={{ presentation: "modal" }}
      />
      <Stack.Screen
        name="Recuperar Matrícula"
        component={ForgetRegistration as FunctionComponent}
        // options={{ presentation: "modal" }}
      />
      <Stack.Screen
        name="Matrícula Enviada"
        component={RegistrationSent as FunctionComponent}
        // options={{ presentation: "modal" }}
      />
    </Stack.Navigator>
  );
};

export { AuthRoutes };
