import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../styles/colors";
import { fonts } from "../../styles/fonts";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  mainContent: {
    margin: "auto",
  },
  textWrapper: {
    padding: 16,
  },

  greetings: {
    fontSize: RFValue(22),
    fontFamily: fonts.bold,
    color: colors.text,
  },
  title: {
    fontSize: RFValue(15),
    fontFamily: fonts.bold,
    color: colors.text,
  },
  normalText: {
    fontSize: RFValue(14),
    fontFamily: fonts.light,
    color: colors.text,
  },
  userInfoWrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: 3,
    height: "auto",
    width: "auto",
  },
  userName: {
    fontSize: RFValue(16),
    fontFamily: fonts.bold,
    marginLeft: 18,
  },
  registrationDataWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    marginVertical: 20,
  },
  registrationIcon: {
    fontSize: RFValue(20),
  },
  registrationDataText: {
    display: "flex",
    marginLeft: 4,
    fontSize: RFValue(14),
    fontFamily: fonts.medium,
    color: colors.text,
  },
  logoutWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    maxWidth: "30%",
  },
  logoutIcon: {
    fontSize: RFValue(20),
    color: colors.redCancel,
  },
  logoutText: {
    fontSize: RFValue(14),
    fontFamily: fonts.medium,
    color: colors.redCancel,
    marginLeft: 5,
  },
});

export { styles };
