import { StyleSheet } from "react-native";
import { getColor } from "../../../styles/colors";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "auto",
    padding: 10,
    backgroundColor: colors.primaryColor,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "space-around",
    alignItems: "center",
  },
  button: {
    width: 35,
    height: 35,
    backgroundColor: colors.white,
    borderRadius: 50,
    margin: 5,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "row",
  },
  image: {
    height: "100%",
    width: "90%",
    resizeMode: "contain",
  },
  none: {
    opacity: 1,
    padding: 10,
  },
});

export { styles };
