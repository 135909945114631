import { StyleSheet, Dimensions } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    marginTop: 10,
  },
  title: {
    fontSize: RFValue(16),
    fontFamily: fonts.bold,
    color: colors.text,
  },
  noAvailableHours: {
    fontSize: RFValue(14),
    fontFamily: fonts.medium,
    color: colors.text,
    textAlign: "center",
    marginTop: 30,
  },
  dateWrapper: {
    height: "auto",
    margin: 18,
    marginTop: 23,
  },
  timeWrapper: {
    height: "auto",
    margin: 18,
    marginTop: 5,
    flexGrow: 1,
  },
  buttonWrapper: {
    marginVertical: 20,
    display: "flex",
    alignItems: "center",
  },
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalTitle: {
    fontFamily: fonts.medium,
    color: colors.text,
    fontSize: RFValue(20),
    textAlign: "center",
  },
});

export { styles };
