import {
  StyleProp,
  Text,
  TextInput,
  TextInputProps,
  TextStyle,
} from "react-native";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { getColor } from "../../../styles/colors";

import { useState } from "react";
import {
  TextInputMask,
  TextInputMaskOptionProp,
  TextInputMaskTypeProp,
} from "react-native-masked-text";
import { useCompany } from "../../../hooks/useCompany";
import { styles } from "./styles";

interface InputWithLabelProps extends TextInputProps {
  label: string;
  value?: any;
  defaultValue?: any;
  editable?: boolean;
  disabled?: boolean;
  placeholder?: string;
  inputType?: "default" | "with-mask";
  options?: TextInputMaskOptionProp;
  maskType?: TextInputMaskTypeProp;
  error?: string;
  additionalInputStyles?: StyleProp<TextStyle>;
  additionalLabelStyles?: StyleProp<TextStyle>;
  onChangeText?: (text: string) => void;
}

const InputComponent = {
  default: TextInput,
  "with-mask": TextInputMask,
};

const InputWithLabel = (props: InputWithLabelProps) => {
  const {
    value,
    defaultValue,
    label,
    editable,
    inputType = "default",
    maskType,
    options,
    placeholder,
    additionalInputStyles,
    additionalLabelStyles,
    error,
    onChangeText,
  } = props;
  const { company } = useCompany();

  const [currentStyle, setCurrentStyle] = useState<StyleProp<TextStyle>>(
    styles.textInputStyle
  );

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  const SelectedInput = InputComponent[inputType];
  return (
    <>
      <Text
        style={[
          styles.textLabel,
          { color: colors.primaryColor },
          additionalLabelStyles,
        ]}
      >
        {label}
      </Text>

      <SelectedInput
        {...props}
        placeholder={placeholder}
        onChangeText={(text: string) => onChangeText && onChangeText(text)}
        type={maskType || "custom"}
        style={[
          currentStyle,
          { borderColor: colors.primaryColor },
          additionalInputStyles,
        ]}
        onBlur={() => setCurrentStyle(styles.textInputStyle)}
        onFocus={() => setCurrentStyle(styles.textInputStyleActive)}
        defaultValue={defaultValue}
        value={value}
        editable={editable}
        options={options}
      />

      {!!error && <Text style={styles.errorText}>{error}</Text>}
    </>
  );
};

export { InputWithLabel };
