import { Linking, Text, TouchableOpacity } from "react-native";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { getColor } from "../../../styles/colors";

import { useCompany } from "../../../hooks/useCompany";
import { styles } from "./styles";

interface PhoneCardProps {
  phone: string;
  stateAcronym: string;
}

const PhoneCard = (props: PhoneCardProps) => {
  const { phone, stateAcronym } = props;

  const { company } = useCompany();
  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={styles.cardWrapper}
      onPress={() => {
        Linking.openURL(`tel:${phone}`);
      }}
    >
      <Text style={[styles.stateAcronym, { color: colors.primaryColor }]}>
        {stateAcronym}
      </Text>
      <Text style={styles.phone}>{phone.padStart(16)}</Text>
    </TouchableOpacity>
  );
};

export { PhoneCard };
