import { isBefore, subHours } from "date-fns";
import { UserProps } from "../../models/userProps";
import { getActiveAppointments } from "../../services/firestore/appointment/getActiveAppointments";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { ParamListBase } from "@react-navigation/native";
import Toast from "react-native-root-toast";
import { specialtyIdToSpecialtyName } from "../../utils/doc24/appointment";

interface HandleGetActiveAppointmentControllerProps {
  user: UserProps;
  navigation: NativeStackNavigationProp<ParamListBase, string, undefined>;
  redirect?: boolean;
}

async function handleGetActiveAppointmentController(
  props: HandleGetActiveAppointmentControllerProps
) {
  const { user, navigation, redirect = true } = props;
  const response = await getActiveAppointments({
    cpf: user?.cpf,
  });

  const activeAppointment = response?.data[0];

  if (response.errorCode || response.errorMessage) {
    Toast.show("Não foi possível salvar seu atendimento", {
      duration: Toast.durations.LONG,
    });
    console.error(
      "[ERROR] [handleGetActiveAppointment]",
      response.errorCode,
      response.errorMessage
    );
    return;
  }

  if (!activeAppointment?.appointmentAt) {
    return;
  }
  const appointmentDate = activeAppointment?.appointmentAt?.toDate();
  const now = new Date();
  const isInsideDayRange = isBefore(
    subHours(new Date(appointmentDate), 24),
    now
  );

  const redirectData = {
    appointmentId: activeAppointment?.id,
    patientName: user?.name || "",
    shouldCount: isInsideDayRange,
    appointmentDate,
    appointmentStatus: activeAppointment?.status,
    appointmentLinkRoom: activeAppointment?.patientRoom,
    specialtyName: specialtyIdToSpecialtyName[activeAppointment?.specialtyId],
  };

  if (activeAppointment?.status === "open" && redirect) {
    navigation.navigate("Detalhes da consulta", redirectData);
  }

  return { appointments: response.data, redirectData };
}

export { handleGetActiveAppointmentController };
