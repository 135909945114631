import { MaterialIcons } from "@expo/vector-icons";
import { StyleProp, Text, TouchableOpacity, ViewStyle } from "react-native";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useCompany } from "../../../hooks/useCompany";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";

interface GoBackProps {
  onPress: () => void;
  additionalStyles?: StyleProp<ViewStyle>;
}

const GoBack = (props: GoBackProps) => {
  const { onPress, additionalStyles } = props;
  const { company } = useCompany();

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[
        styles.back,
        { backgroundColor: colors.secondaryColor },
        additionalStyles,
      ]}
      onPress={onPress}
    >
      <MaterialIcons name="arrow-back-ios" size={24} style={styles.arrowBack} />
      <Text style={styles.backText}>Voltar</Text>
    </TouchableOpacity>
  );
};

export { GoBack };
