import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    fontSize: RFValue(11),
    fontFamily: fonts.medium,
  },
  checkbox: {
    height: 20,
    width: 20,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginRight: 5,
    borderWidth: 1,
  },
  checkIcon: {
    width: 15,
    height: 15,
    fontSize: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    margin: "auto",
    color: colors.white,
  },
});

export { styles };
