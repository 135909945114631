import axios from "axios";
import { Plans, Providers } from "../../models/gleebem.core";

interface ScheduleOnDemandProps {
  patientId: string;
  plan: Plans;
  provider: Providers;
}

async function scheduleOnDemand(props: ScheduleOnDemandProps) {
  const { patientId } = props;

  if (!patientId) {
    return {
      responseCode: 400,
      message: "'patientId' is a required field!",
    };
  }

  try {
    const response = await axios.post(
      `${process.env.DR_TIS_API}/api/anamnesis/onDemand`,
      {
        ...props,
        queueId: process.env.QUEUE_ID,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesskey: process.env.ACCESS_KEY,
        },
      }
    );

    return {
      message: "[scheduleOnDemand.ts] appointment successfully scheduled!",
      responseCode: response.status,
      data: response.data,
    };
  } catch (error) {
    return {
      message: "Não foi possível iniciar seu atendimento sob demanda!",
      responseCode: 550,
      error,
    };
  }
}

export { scheduleOnDemand };
