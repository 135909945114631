import { AntDesign, SimpleLineIcons } from "@expo/vector-icons";
import { Text, TouchableOpacity, View } from "react-native";
import { getColor } from "../../../styles/colors";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { styles } from "./styles";
import { useAuth } from "../../../hooks/useAuth";
import { useCompany } from "../../../hooks/useCompany";

interface HeaderWithGoBackProps {
  title: string;
  goBackFunction: () => void;
  description?: string;
  icon?: "x" | "arrow";
}

const HeaderWithGoBack = (props: HeaderWithGoBackProps) => {
  const { title, description, goBackFunction, icon } = props;

  const { company } = useCompany();

  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });

  return (
    <View style={[styles.header, { backgroundColor: colors.primaryColor }]}>
      <TouchableOpacity
        style={styles.goBackBtn}
        activeOpacity={0.7}
        onPress={() => goBackFunction()}
      >
        {icon === "x" ? (
          <AntDesign name="close" size={24} color={colors.primaryColor} />
        ) : (
          <SimpleLineIcons
            name="arrow-left"
            size={25}
            color={colors.primaryColor}
          />
        )}
      </TouchableOpacity>
      <View>
        <Text style={styles.title}>{title}</Text>
        <Text
          style={[
            styles.description,
            { display: !!description ? "flex" : "none" },
          ]}
        >
          {description}
        </Text>
      </View>
    </View>
  );
};

export { HeaderWithGoBack };
