import { FunctionComponent } from "react";

import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import { History } from "../pages/History";
import { Home } from "../pages/Home";
import { ScheduleAppointment } from "../pages/ScheduleAppointment";

import { getColor } from "../styles/colors";

import { Platform } from "react-native";
import { companyDefaultTheme } from "../../assets/theme/companyColors";
import { useCompany } from "../hooks/useCompany";

const Tab = createBottomTabNavigator();

const TabRoutes = () => {
  const { company } = useCompany();
  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });

  const isWebDevice = Platform.OS === "web";

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarInactiveTintColor: colors.secondaryColor,
        tabBarActiveTintColor: colors.secondaryColor,
        headerShown: false,
      }}
      initialRouteName="Home"
    >
      <Tab.Screen
        name="Home"
        component={Home as FunctionComponent<{}>}
        options={{
          tabBarLabel: "Início",
          tabBarIcon: ({ size, color, focused }) =>
            focused ? (
              <Ionicons name="md-home" size={size} color={color} />
            ) : (
              <Ionicons name="md-home-outline" size={size} color={color} />
            ),
        }}
      />
      <Tab.Screen
        name="Agenda"
        component={ScheduleAppointment as FunctionComponent<{}>}
        options={{
          tabBarIcon: ({ size, color, focused }) =>
            focused ? (
              <Ionicons name="md-calendar" size={size} color={color} />
            ) : (
              <Ionicons name="md-calendar-outline" size={size} color={color} />
            ),
        }}
      />
      <Tab.Screen
        name="Histórico"
        component={History as FunctionComponent<{}>}
        options={{
          tabBarIcon: ({ size, color, focused }) =>
            focused ? (
              <MaterialCommunityIcons
                name="clock-time-four"
                size={size}
                color={color}
              />
            ) : (
              <MaterialCommunityIcons
                name="clock-time-four-outline"
                size={size}
                color={color}
              />
            ),
        }}
      />
    </Tab.Navigator>
  );
};

export { TabRoutes };
