import { Fragment, useEffect, useState } from "react";
import {
  // BackHandler,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  Text,
  View,
} from "react-native";
import SafeAreaView from "react-native-safe-area-view";

import { DefaultButton } from "../../Buttons/DefaultButton";
import { AppointmentInfoCard } from "../../Cards/AppointmentInfoCard";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import ModalBottomSheet from "../../Modals/ModalBottomSheet";

import { styles } from "./styles";

import { useAuth } from "../../../hooks/useAuth";

import { ParamListBase } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { appointmentDataProps } from "../../../models/appointmentDataProps";
import { SpecialtyProps } from "../../../models/doc24/specialtyProps";
import { Plans } from "../../../models/gleebem.core";
import { createAppointmentApi } from "../../../services/api/appointment/createAppointment";

import { isAfter } from "date-fns";
import { AvailableAppointment } from "../../../models/doc24/availableAppointmentProps";
import { getAvailableHoursApi } from "../../../services/api/appointment/getAvailableHours";
import { SelectAppointmentCard } from "../../Cards/SelectAppointmentCard";
import { BasicLoading } from "../../Loadings/BasicLoading";
import { ThreeDotsLoading } from "../../Loadings/ThreeDotsLoading";
interface Doc24ScheduleAppointmentProps {
  setIsOnFirstStep: React.Dispatch<React.SetStateAction<boolean>>;
  navigation: NativeStackNavigationProp<ParamListBase, string, undefined>;
  specialty: SpecialtyProps;
}

function serializeAvailableHours(availableAppointments: any) {
  const availableHours: AvailableAppointment[] = [];

  Object.values(availableAppointments).forEach((weekdaySet: any) => {
    weekdaySet.forEach((availableHour: AvailableAppointment) => {
      const appointmentTime = new Date(
        availableHour.appointmentTime
      ).toISOString();
      const currentTime = new Date();

      if (isAfter(new Date(appointmentTime), currentTime)) {
        availableHours.push({ ...availableHour, appointmentTime });
      }
    });
  });

  return availableHours;
}

const Doc24ScheduleAppointment = (props: Doc24ScheduleAppointmentProps) => {
  const { setIsOnFirstStep, navigation, specialty } = props;

  const { user } = useAuth();

  const [appointmentData, setAppointmentData] = useState<appointmentDataProps>(
    {} as appointmentDataProps
  );

  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showBottomSheetModal, setShowBottomSheetModal] =
    useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const [availableHours, setAvailableHours] = useState<AvailableAppointment[]>(
    []
  );

  const [isFetchingAvailableHours, setIsFetchingAvailableHours] =
    useState<boolean>(false);

  const [isPaginating, setIsPaginating] = useState<boolean>(false);

  let pagination = 100;

  useEffect(() => {
    const handleGetAvailableHours = async () => {
      setIsFetchingAvailableHours(true);
      const response = await getAvailableHoursApi({
        provider: user.provider,
        plan: user.plan,
        specialtyId: specialty.specialtyId,
        queries: {
          limit: 100,
          daysAhead: 100,
        },
      });

      const availableHoursSerialized = serializeAvailableHours(
        response.data.availableAppointments
      );

      setAvailableHours(availableHoursSerialized);
      setIsFetchingAvailableHours(false);
    };

    handleGetAvailableHours();

    // BackHandler.addEventListener("hardwareBackPress", () => {
    //   setIsOnFirstStep(true);
    //   return null;
    // });
  }, []);

  async function handleSchedule() {
    let appointmentTime = appointmentData.ISODate;

    const response: any = await createAppointmentApi({
      plan: user.plan || Plans.BASIC,
      provider: user.provider,
      patientId: user.id,
      patient: user,
      specialtyId: specialty.specialtyId,
      professionalId: appointmentData.doctorId,
      shiftId: appointmentData.shiftId,
      duration: 60,
      appointmentTime,
    });

    if (!!response.error) {
      return setShowErrorModal(true);
    }

    return setShowBottomSheetModal(true);
  }

  async function handleFetchAvailableHours() {
    setIsPaginating(true);

    const { data } = await getAvailableHoursApi({
      plan: user.plan,
      provider: user.provider,
      specialtyId: specialty.specialtyId,
      queries: {
        date: availableHours[availableHours.length - 1].date,
        daysAhead: 4,
        limit: 50,
        offset: pagination,
      },
    });

    const availableHoursSerialized = serializeAvailableHours(
      data.availableAppointments
    );

    setAvailableHours((oldValues) => [
      ...oldValues,
      ...availableHoursSerialized,
    ]);

    pagination += 50;
    setIsPaginating(false);
  }

  async function handleScroll(event: NativeSyntheticEvent<NativeScrollEvent>) {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;

    const isOnEnd =
      layoutMeasurement.height + contentOffset.y + 5 >= contentSize.height;

    if (isOnEnd) {
      await handleFetchAvailableHours();
    }
  }

  return (
    <SafeAreaView style={styles.container} forceInset={{ top: "always" }}>
      <ScrollView onScroll={handleScroll} scrollEventThrottle={16}>
        {!isFetchingAvailableHours && !!availableHours.length && (
          <Text style={styles.specialty}>
            Especialidade: {specialty.specialty}
          </Text>
        )}

        {isFetchingAvailableHours ? (
          <BasicLoading />
        ) : (
          availableHours
            .sort((a, b) => {
              const dateA = new Date(a.appointmentTime).getTime();
              const dateB = new Date(b.appointmentTime).getTime();
              return dateA - dateB;
            })
            .map((availableHour) => {
              return (
                <Fragment key={`${availableHour.id}_${Math.random()}`}>
                  <SelectAppointmentCard
                    appointmentTime={availableHour.appointmentTime}
                    isActive={appointmentData.shiftId === availableHour.id}
                    doctorName={availableHour.professional}
                    onPress={() => {
                      setAppointmentData({
                        ISODate: availableHour.appointmentTime,
                        doctorId: availableHour.professionalId,
                        shiftId: availableHour.id,
                      });
                    }}
                  />
                </Fragment>
              );
            })
        )}

        {!isFetchingAvailableHours && isPaginating && <ThreeDotsLoading />}

        {!isFetchingAvailableHours && availableHours.length === 0 && (
          <Text style={styles.noAvailableHours}>
            Sem horários disponíveis para {specialty.specialty}
          </Text>
        )}

        <ModalBottomSheet
          title={"Agendamento efetuado com sucesso!"}
          onRequestClose={() => {
            setAppointmentData({} as appointmentDataProps);
            setShowBottomSheetModal(false);
            navigation.navigate("Histórico");
          }}
          visible={showBottomSheetModal}
        >
          <View style={styles.modalContainer}>
            <Text style={styles.modalTitle}>
              Agendamento efetuado com sucesso!
            </Text>

            <AppointmentInfoCard
              patientName={user.name || ""}
              appointmentDate={appointmentData.ISODate}
              border={true}
            />
            <DefaultButton
              onPress={() => {
                setAppointmentData({} as appointmentDataProps);
                setIsOnFirstStep(true);
                setShowBottomSheetModal(false);
                navigation.navigate("Home");
              }}
              text={"Ir para o início"}
              buttonHeight={35}
              buttonWidth={256}
            />
          </View>
        </ModalBottomSheet>

        <ConfirmationModal
          isModalOpen={showErrorModal}
          setIsModalOpen={setShowErrorModal}
          title="Operação falhou"
          description="Não foi possível agendar a sua consulta. Tente novamente mais tarde!"
          confirmButton={{
            color: "grey",
            onClick: () => {
              setShowErrorModal(false);
              navigation.navigate("Home");
            },
            text: "Ok",
          }}
          dontShowCancelButton
        />

        <ConfirmationModal
          title="Agendar consulta!"
          description="Deseja agendar essa consulta consulta?"
          cancelButton={{
            onClick: () => setShowConfirmationModal(false),
            text: "Não",
          }}
          confirmButton={{
            text: "Sim",
            color: "tertiary",
            onClick: async () => {
              setShowConfirmationModal(false);
              await handleSchedule();
            },
          }}
          isModalOpen={showConfirmationModal}
          setIsModalOpen={setShowConfirmationModal}
        >
          <AppointmentInfoCard
            patientName={user.name || ""}
            appointmentDate={appointmentData.ISODate}
            border={true}
          />
        </ConfirmationModal>
      </ScrollView>
      <View style={styles.buttonWrapper}>
        <DefaultButton
          onPress={() => {
            setShowConfirmationModal(true);
          }}
          text="Agendar"
          additionalStyles={{
            alignSelf: "center",
          }}
          disabled={!appointmentData.shiftId}
        />
      </View>
    </SafeAreaView>
  );
};

export { Doc24ScheduleAppointment };
