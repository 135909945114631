interface colorsProps {
  company?: {
    primaryColor: string;
    secondaryColor?: string;
    tertiaryColor?: string;
  };
}

function getColor({ company }: colorsProps) {
  return {
    mainBlue: "#49A4CE",
    white: "#FFFFFF",
    darkWhite: "#F2F2F2",
    platinum: "#F4F4F4",
    neutralGray: "#C4C4C4",
    midBlack: "#1B1212",
    lightBlack: "#444444",
    grey: "#bdc3c7",
    midGrey: "#8999AC",
    darkGray: "#576575",
    text: "#222f3e",
    textRed: "#FA0000",
    textYellow: "#FFD600",
    textGreen: "#36AA00",
    transparentBlack: "#00000050",
    textOrange: "#FF9900",
    transparent: "rgba(0, 0, 0, 0)",
    redCancel: "#FF0000",
    primaryColor: company?.primaryColor || "#49A4CE",
    secondaryColor:
      company?.secondaryColor || company?.primaryColor || "#49A4CE",
    tertiaryColor: company?.tertiaryColor || company?.primaryColor || "#49A4CE",
  };
}

export { getColor };
