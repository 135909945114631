interface ValidKeysAndValuesProps {
  key: string;
  value: any;
}

interface CreateQueryUrlProps {
  attributesList: any;
  url: string;
}

function createQueryUrl(props: CreateQueryUrlProps): string {
  const { attributesList, url } = props;
  const values = Object.values(attributesList);
  const keys = Object.keys(attributesList);

  const validKeysAndValues: ValidKeysAndValuesProps[] = [];

  let urlRef = url;

  values.forEach((value, index) => {
    if (!!value && !!keys[index]) {
      validKeysAndValues.push({ key: keys[index], value });
    }
  });

  validKeysAndValues.forEach(({ key, value }, index) => {
    if (index === 0) {
      return (urlRef += `?${key}=${value}`);
    }

    urlRef += `&${key}=${value}`;
  });

  return urlRef;
}

export { createQueryUrl };
