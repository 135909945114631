import React, { useEffect } from "react";

import { AntDesign } from "@expo/vector-icons";
import {
  Animated,
  Dimensions,
  Easing,
  StyleProp,
  Text,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useCompany } from "../../../hooks/useCompany";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";

interface DefaultButtonProps {
  text: string;
  onPress: () => Promise<void> | void;
  isLoading?: boolean;
  buttonWidth?: number;
  buttonHeight?: number;
  disabled?: boolean;
  additionalStyles?: StyleProp<ViewStyle>;
  textColor?: string;
}

const DefaultButton = (props: DefaultButtonProps) => {
  const {
    text,
    onPress,
    buttonHeight,
    buttonWidth,
    disabled,
    additionalStyles,
    isLoading = false,
    textColor,
  } = props;
  const { company } = useCompany();

  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });

  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  const buttonWidthDefault = (windowWidth * 256) / 360;
  const buttonHeightDefault = (windowHeight * 35) / 760;

  const spinValue = new Animated.Value(0);

  const rotate = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  });

  useEffect(() => {
    spin();
  }, []);

  function spin() {
    spinValue.setValue(0);
    Animated.loop(
      Animated.timing(spinValue, {
        toValue: 1,
        duration: 1000,
        easing: Easing.linear,
        useNativeDriver: true,
      })
    ).start();
  }

  return (
    <TouchableOpacity
      style={[
        { ...styles.button, backgroundColor: colors.tertiaryColor },
        (disabled || isLoading) && {
          backgroundColor: colors.grey,
        },
        {
          width: buttonWidth || buttonWidthDefault,
          height: buttonHeight || buttonHeightDefault,
        },
        additionalStyles,
      ]}
      activeOpacity={0.7}
      onPress={!isLoading && !disabled ? onPress : () => {}}
      disabled={disabled || isLoading}
    >
      <Text style={[styles.text, !!textColor && { color: textColor }]}>
        {isLoading ? (
          <Animated.View style={{ transform: [{ rotate }] }}>
            <AntDesign name="loading2" size={24} color={colors.white} />
          </Animated.View>
        ) : (
          text
        )}
      </Text>
    </TouchableOpacity>
  );
};

export { DefaultButton };
