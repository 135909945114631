import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../config/firebase";

async function getProviderByKey(key: string) {
  try {
    const providerQuery = query(
      collection(firestore, "providers"),
      where("key", "==", key)
    );

    const response = await getDocs(providerQuery);

    return {
      message: "Provedor encontrado!",
      data: response.docs[0]?.data(),
    };
  } catch (error: any) {
    console.error("[ERROR][getProviderByKey.ts]", error);
    return {
      message: "Não foi possível encontrar o provedor!",
      errorCode: error.code,
      errorMessage: error.message,
      data: {},
    };
  }
}

export { getProviderByKey };
