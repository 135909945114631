import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    marginTop: 23,
  },

  instructionText: {
    fontSize: RFValue(16),
    fontFamily: fonts.bold,
    color: colors.text,
    textAlign: "left",
    marginLeft: "8%",
  },
  specialtiesWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "auto",
    flexWrap: "wrap",
    justifyContent: "center",
    alignSelf: "center",
  },
  buttonWrapper: {
    marginVertical: 20,
    display: "flex",
    alignItems: "center",
  },
  loadingWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    width: "100%",
    minHeight: 150,
    maxHeight: 250,
  },
});

export { styles };
