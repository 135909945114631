import { StyleSheet } from "react-native";
import { getColor } from "../../../styles/colors";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  header: {
    display: "flex",
    width: "100%",
    height: "auto",
    flexDirection: "column",
    backgroundColor: colors.primaryColor,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: "auto",
    minWidth: 200,
    maxWidth: 600,
    resizeMode: "contain",
  },
});

export { styles };
