import * as React from "react";
// import { Splash } from "../pages/Splash";
import { NavigationContainer } from "@react-navigation/native";
import { useContext } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { AuthContext } from "../contexts/Auth";
import { AppRoutes } from "./stack.app.route";
import { AuthRoutes } from "./stack.auth.route";

const Routes = () => {
  const { isSigned } = useContext(AuthContext);

  return (
    <SafeAreaProvider>
      <NavigationContainer independent={true}>
        {isSigned ? <AppRoutes /> : <AuthRoutes />}
      </NavigationContainer>
    </SafeAreaProvider>
  );
};

export { Routes };
