import { useEffect, useState } from "react";
import {
  Linking,
  SafeAreaView,
  Text,
  TouchableOpacity,
  Image,
} from "react-native";

import {
  DocumentData,
  QuerySnapshot,
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { differenceInMinutes, isBefore, parseISO, subHours } from "date-fns";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

import { GoBack } from "../../../components/Buttons/GoBack";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";

import { firestore } from "../../../config/firebase";

import { useAuth } from "../../../hooks/useAuth";

import { scheduleOnDemand } from "../../../services/drTis/scheduleOnDemand";

import { getColor } from "../../../styles/colors";
import { styles } from "./styles";
import { ParamListBase } from "@react-navigation/native";
import { useCompany } from "../../../hooks/useCompany";

interface ErrorMessageProps {
  title: string;
  description: string;
}

interface DrTisScheduleOnDemandPageProps {
  navigation: NativeStackNavigationProp<ParamListBase, string, undefined>;
}

const DrTisScheduleOnDemandPage = (props: DrTisScheduleOnDemandPageProps) => {
  const { navigation } = props;

  const { user } = useAuth();
  const { company } = useCompany();

  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps>();
  const [appointmentURL, setAppointmentURL] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  const findNextAppointments = (appointments: QuerySnapshot<DocumentData>) => {
    const now = new Date();

    const nextAppointments = appointments.docs
      .map((appointment) => appointment.data())
      .filter((appointment) => {
        const minutesDifference = differenceInMinutes(
          parseISO(appointment.appointmentTime),
          now
        );
        const isInsideDayRange = isBefore(
          subHours(parseISO(appointment.appointmentTime), 24),
          now
        );

        if (
          minutesDifference < 60 &&
          minutesDifference >= -20 &&
          isInsideDayRange
        ) {
          return appointment;
        }
      });

    return nextAppointments;
  };

  const handleSchedule = async () => {
    setLoading(true);

    if (!user.id) {
      return;
    }
    const response = await scheduleOnDemand({
      patientId: user?.id,
      plan: user?.plan,
      provider: user?.provider,
    });
    setAppointmentURL(response.data?.patientRoom);

    if (response.error) {
      setErrorMessage({
        title: "Falha no atendimento...",
        description: response.message,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    const collectionRef = collection(firestore, "appointments");
    const appointmentsQuery = query(
      collectionRef,
      orderBy("appointmentAt", "desc"),
      where("patient.cpf", "==", user.cpf),
      where("status", "==", "open")
    );

    const unsubscribeAppointments = onSnapshot(
      appointmentsQuery,
      (querySnapShot) => {
        const nextAppointment = findNextAppointments(querySnapShot)[0];

        if (nextAppointment?.id) {
          navigation.navigate("Detalhes da consulta", {
            appointmentId: nextAppointment?.id,
            patientName: user?.name || "",
            shouldCount: true,
            appointmentDate: nextAppointment?.appointmentTime,
            appointmentStatus: nextAppointment?.status,
            appointmentLinkRoom: nextAppointment?.patientRoom,
          });
        }
      }
    );

    return () => {
      unsubscribeAppointments();
    };
  }, []);

  if (!loading && appointmentURL) {
    setTimeout(() => {
      Linking.openURL(appointmentURL);
      setAppointmentURL("");
      navigation.navigate("Home");
    }, 2500);
  }

  return (
    <SafeAreaView style={styles.container}>
      {!loading && !errorMessage?.title && !appointmentURL && (
        <>
          <TouchableOpacity
            style={[
              styles.startButton,
              {
                backgroundColor: colors.primaryColor,
              },
            ]}
            activeOpacity={0.7}
            onPress={() => setShowConfirmationModal(true)}
          >
            <Text style={styles.startButtonText}>Iniciar</Text>
          </TouchableOpacity>
          <GoBack
            onPress={() => navigation.goBack()}
            additionalStyles={{ marginTop: "30%" }}
          />
        </>
      )}

      {loading && (
        <>
          <Image
            source={require("../../../../assets/gifs/searching-doctors.gif")}
          />

          <Text style={[styles.loadingMessage, { color: colors.primaryColor }]}>
            Aguarde a sua vez...
          </Text>
        </>
      )}

      {!loading && appointmentURL && (
        <>
          <Image
            source={require("../../../../assets/gifs/congratulations.gif")}
          />
          <Text style={styles.congratsText}>Chegou a sua vez!!!</Text>
        </>
      )}

      {!loading && errorMessage?.title && errorMessage.description && (
        <>
          <Text style={styles.errorTitle}>{errorMessage.title}</Text>
          <Text style={styles.errorDescription}>
            {errorMessage.description}
          </Text>
          <GoBack
            onPress={() => navigation.goBack()}
            additionalStyles={{ marginTop: "30%" }}
          />
        </>
      )}

      <ConfirmationModal
        title="Atendimento!"
        description="Deseja iniciar o atendimento?"
        confirmButton={{
          text: "Sim",
          color: "blue",
          onClick: async () => {
            setShowConfirmationModal(false);
            await handleSchedule();
          },
        }}
        cancelButton={{
          text: "Não",
          onClick: () => setShowConfirmationModal(false),
        }}
        isModalOpen={showConfirmationModal}
        setIsModalOpen={setShowConfirmationModal}
      />
    </SafeAreaView>
  );
};

export { DrTisScheduleOnDemandPage };
