import { Fontisto } from "@expo/vector-icons";
import { format } from "date-fns";
import { Text, View } from "react-native";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useCompany } from "../../../hooks/useCompany";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";

interface AppointmentInfoCardProps {
  patientName: string;
  appointmentDate: string;
  specialtyName?: string;
  border?: boolean;
}

const AppointmentInfoCard = (props: AppointmentInfoCardProps) => {
  const { patientName, appointmentDate, border, specialtyName } = props;

  const { company } = useCompany();

  const parsedAppointmentDate = !!Date.parse(appointmentDate)
    ? new Date(appointmentDate)
    : "";

  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });

  return (
    <View
      style={[
        styles.infoWrapper,
        border && {
          borderColor: colors.darkWhite,
          borderWidth: 2,
        },
      ]}
    >
      <Fontisto
        name="doctor"
        style={[styles.doctorIcon, { color: colors.tertiaryColor }]}
      />
      <View style={styles.modalTextInfoWrapper}>
        <Text
          style={[styles.modalInfoTextTitle, { color: colors.tertiaryColor }]}
        >
          Consulta de telemedicina
        </Text>
        <View style={styles.textLine}>
          <Text style={styles.smallModalText}>Online</Text>

          {specialtyName && (
            <>
              <View
                style={[
                  styles.separator,
                  { backgroundColor: colors.primaryColor },
                ]}
              />
              <Text style={styles.specialtyName}>{specialtyName}</Text>
            </>
          )}
        </View>
        <Text style={styles.modalDescriptionText}>{patientName}</Text>
        <Text style={styles.modalDescriptionText}>
          {typeof parsedAppointmentDate === "object" &&
            `${format(parsedAppointmentDate, "dd/MM/yyyy")} às
          ${format(parsedAppointmentDate, "HH:mm")}`}
        </Text>
      </View>
    </View>
  );
};

export { AppointmentInfoCard };
