import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  title: {
    fontSize: RFValue(13),
    fontFamily: fonts.bold,
    marginRight: 10,
    color: colors.text,
    display: "flex",
    flexDirection: "row",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
  },
  dropdown: {
    paddingVertical: 10,
    paddingHorizontal: 5,
    borderColor: colors.darkWhite,
    borderRadius: 10,
    borderWidth: 2,
    marginVertical: 15,
  },
  userIcon: {
    fontSize: RFValue(25),
    marginRight: 10,
  },

  indicatorText: {
    fontSize: RFValue(10),
    fontFamily: fonts.bold,
    display: "flex",
    flexDirection: "row",
  },
});

export { styles };
