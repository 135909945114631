import {
  DocumentData,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../../config/firebase";

interface GetActiveAppointmentProps {
  cpf: string;
}

interface GetActiveAppointentPropsReturn {
  message: string;
  data: DocumentData[];
  errorCode?: string;
  errorMessage?: string;
}

async function getActiveAppointments(
  props: GetActiveAppointmentProps
): Promise<GetActiveAppointentPropsReturn> {
  const { cpf } = props;

  try {
    const collectionRef = collection(firestore, "appointments");
    const appointmentsQuery = query(
      collectionRef,
      orderBy("createdAt"),
      where("patient.cpf", "==", cpf),
      where("status", "==", "open")
    );
    const appointmentsSnapshot = await getDocs(appointmentsQuery);
    const serializedSnapshot = appointmentsSnapshot.docs.map(
      (currentAppointment) => currentAppointment.data()
    );

    return {
      message: "Your active appointmens were successfully requested!",
      data: serializedSnapshot,
    };
  } catch (error: any) {
    console.error("[Error][getActiveAppointments] ", error);
    return {
      message: "It wasn't possible to get your active appointments",
      data: [],
      errorCode: error?.code,
      errorMessage: error?.message,
    };
  }
}

export { getActiveAppointments };
