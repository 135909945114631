import axios from "axios";

import { UserProps } from "../../../models/userProps";
import { SimpleApiPropsReturn } from "../../../models/core.props-return";

export interface UpdateUserApiProps {
  userData: UserProps;
  id: string;
}

async function updateUserApi(
  props: UpdateUserApiProps
): Promise<SimpleApiPropsReturn> {
  try {
    const { id, userData } = props;

    const response = await axios.patch(
      `${process.env.GLEEBEM_API}/user/update/${id}`,
      userData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.GLEEBEM_ACCESS_KEY,
        },
      }
    );

    return {
      statusCode: response.status,
      message: "Usuário atualizado com sucesso!",
      data: response.data,
    };
  } catch (error: any) {
    return {
      message:
        "Não foi possível atualizar o usuário, tente novamente mais tarde!",
      statusCode: error.status,
      error: error.response,
    };
  }
}

export { updateUserApi };
