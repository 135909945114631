import { StyleSheet } from "react-native";
import { RFPercentage } from "react-native-responsive-fontsize";
import { getColor } from "../../../styles/colors";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    alignContent: "center",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  animation: {
    width: RFPercentage(10),
    height: RFPercentage(10),
  },
});

export { styles };
