import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../styles/colors";
import { fonts } from "../../styles/fonts";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  progressCircleBackground: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: colors.white,
  },
  progressCircleText: {
    fontFamily: fonts.medium,
    color: colors.text,
    fontSize: RFValue(25),
  },
  title: {
    fontFamily: fonts.medium,
    fontSize: RFValue(20),
    color: colors.text,
    margin: 5,
    textAlign: "center",
  },
  subtitle: {
    fontFamily: fonts.medium,
    fontSize: RFValue(14),
    color: colors.text,
    margin: 5,
    textAlign: "center",
  },
  buttonWrapperLInk: {
    flexDirection: "column",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 35,
  },
  buttonWrapper: {
    flexDirection: "column",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 30,
  },
  modalContentWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalTitle: {
    fontFamily: fonts.medium,
    color: colors.text,
    fontSize: RFValue(20),
    textAlign: "center",
    flexGrow: 1,
  },
  buttonsContainer: {
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
});

export { styles };
