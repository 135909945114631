import { StyleProp, Text, View, ViewStyle } from "react-native";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { getColor } from "../../../styles/colors";

import { useCompany } from "../../../hooks/useCompany";
import { styles } from "./styles";

interface RadioInputProps {
  onPress: () => void;
  label: string;
  value: string;
  selectedValue: string;
  additionalContainerStyles?: StyleProp<ViewStyle>;
  disabled?: boolean;
}

const RadioInput = (props: RadioInputProps) => {
  const {
    onPress,
    label,
    value,
    selectedValue,
    disabled,
    additionalContainerStyles,
  } = props;
  const { company } = useCompany();

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  return (
    <View
      style={[
        styles.container,
        additionalContainerStyles,
        { opacity: disabled ? 0.4 : 1 },
      ]}
      onTouchEnd={() => {
        if (disabled) {
          return;
        }
        onPress();
      }}
    >
      <View
        style={[
          styles.externalElement,
          {
            backgroundColor:
              value === selectedValue
                ? colors.primaryColor
                : colors.neutralGray,
          },
        ]}
      >
        <View
          style={[
            styles.innerElement,
            {
              backgroundColor:
                value === selectedValue
                  ? colors.primaryColor
                  : colors.neutralGray,
            },
          ]}
        />
      </View>
      <Text style={styles.label}>{label}</Text>
    </View>
  );
};

export { RadioInput };
