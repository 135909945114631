import { Linking, Text, View } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { DefaultButton } from "../../components/Buttons/DefaultButton";
import { styles } from "./styles";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";
import { TextWithIcon } from "../../components/CustomTexts/TextWithIcon";
import {
  PreRegistrationFormAction,
  PreRegistrationFormState,
} from "../../reducers/preRegistrations/preRegistrationFormReducer";
import { getColor } from "../../styles/colors";
import { Checkbox } from "../../components/Inputs/Checkbox";

interface Step3Props {
  setSelectedStep: React.Dispatch<React.SetStateAction<1 | 2 | 3>>;
  preRegistrationState: PreRegistrationFormState;
  handleApproveUser: () => Promise<void>;
  acceptTermsOfUse?: boolean;
  setAcceptTermsOfUse?: React.Dispatch<React.SetStateAction<boolean>>;
  termsOfUse?: string;
}

const Step3 = (props: Step3Props) => {
  const {
    setSelectedStep,
    preRegistrationState,
    handleApproveUser,
    termsOfUse,
    acceptTermsOfUse,
    setAcceptTermsOfUse,
  } = props;

  const colors = getColor({ company: companyDefaultTheme });

  return (
    <>
      <View style={styles.registrationDataContainer}>
        <View style={styles.registrationDataWrapper}>
          <View style={styles.header}>
            <View style={styles.stepIndicatorWrapper}>
              <Text style={styles.currentStepText}>3</Text>
              <Text style={styles.totalStepsText}>/3</Text>
            </View>
            <View style={styles.stepTitleWrapper}>
              <MaterialIcons
                name="app-registration"
                style={styles.registrationIcon}
              />
              <Text style={styles.headerText}>Preencha seus dados</Text>
            </View>
          </View>
          <Text style={styles.description}>
            Revise todos seus dados antes de continuar:
          </Text>

          <View>
            <TextWithIcon
              iconType="user"
              keyText="Nome"
              textValue={`${preRegistrationState.name}${
                preRegistrationState.lastName
                  ? ` ${preRegistrationState.lastName}`
                  : ""
              }`}
              additionalStyles={{ marginTop: 16 }}
            />
            <TextWithIcon
              iconType="phone"
              keyText="Telefone"
              textValue={preRegistrationState.phone}
              additionalStyles={{ marginTop: 16 }}
            />
            <TextWithIcon
              iconType="cpf"
              keyText="CPF"
              textValue={preRegistrationState.cpf}
              additionalStyles={{ marginTop: 16 }}
            />
            <TextWithIcon
              iconType="registration"
              keyText="Matrícula"
              textValue={preRegistrationState.registration}
              additionalStyles={{ marginTop: 16 }}
            />
            <TextWithIcon
              iconType="email"
              keyText="Email"
              textValue={preRegistrationState.email}
              additionalStyles={{ marginTop: 16 }}
            />
            <TextWithIcon
              iconType="birthdate"
              keyText="Data de nascimento"
              textValue={preRegistrationState.birthdate}
              additionalStyles={{ marginTop: 16 }}
            />
            <TextWithIcon
              iconType={
                preRegistrationState.gender === "Masculino"
                  ? "masculine"
                  : "feminine"
              }
              keyText="Gênero"
              textValue={preRegistrationState.gender}
              additionalStyles={{ marginTop: 16 }}
            />
          </View>
          {!!termsOfUse && !!setAcceptTermsOfUse && (
            <View style={styles.termsOfUseWrapper}>
              <Checkbox
                label="Li e concordo com os"
                onPress={() => setAcceptTermsOfUse(!acceptTermsOfUse)}
                value={!!acceptTermsOfUse}
              />
              <View
                style={styles.termsOfUse}
                onTouchEnd={() => {
                  Linking.openURL(termsOfUse);
                }}
                hitSlop={{ top: 5, bottom: 5, right: 5 }}
              >
                <Text style={styles.termsOfUseText}>termos de uso.</Text>
              </View>
            </View>
          )}
        </View>
      </View>

      <View style={styles.buttonWrapper}>
        <DefaultButton
          text={"Voltar"}
          onPress={async () => {
            setSelectedStep(2);
          }}
          additionalStyles={{
            marginTop: 20,
            backgroundColor: colors.transparent,
            borderWidth: 2,
            borderColor: colors.primaryColor,
          }}
          buttonWidth={130}
          textColor={colors.primaryColor}
        />
        <DefaultButton
          text={"Continuar"}
          onPress={async () => {
            await handleApproveUser();
          }}
          additionalStyles={{ marginTop: 20 }}
          buttonWidth={130}
          disabled={!acceptTermsOfUse}
        />
      </View>
    </>
  );
};

export { Step3 };
