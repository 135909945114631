import { View, Text } from "react-native";

import { styles } from "./styles";

const ProviderUnavailable = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.infoText}>
        Seu provedor não é válido. Entre em contato com a nossa equipe.
      </Text>
    </View>
  );
};

export { ProviderUnavailable };
