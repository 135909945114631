import { Dimensions, Image, View } from "react-native";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useCompany } from "../../../hooks/useCompany";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";

interface HeaderWithPhotosProps {
  logoURL?: string;
  bannerURL?: string;
}

const HeaderWithPhotos = (props: HeaderWithPhotosProps) => {
  const { logoURL, bannerURL } = props;
  const { company } = useCompany();
  const colors = getColor({ company: company ? company : companyDefaultTheme });

  const windowWidth = Dimensions.get("window").width;

  const mainImagePercentageProporptions = {
    width: 0.7083,
    height: 0.351,
  };

  return (
    <View style={[styles.header, { backgroundColor: colors.primaryColor }]}>
      <Image
        source={
          !!logoURL ? { uri: logoURL } : require("../../../../assets/logo.png")
        }
        style={styles.logo}
        resizeMode="contain"
      />
      <Image
        source={
          !!bannerURL
            ? { uri: bannerURL }
            : require("../../../../assets/doctors.png")
        }
        style={{
          width: windowWidth * mainImagePercentageProporptions.width,
          height: windowWidth * mainImagePercentageProporptions.height,
        }}
        resizeMode="cover"
      />
    </View>
  );
};

export { HeaderWithPhotos };
