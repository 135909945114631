import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    color: colors.primaryColor,
    fontSize: 13,
    marginRight: 2,
  },
  keyText: {
    fontFamily: fonts.medium,
    fontSize: RFValue(12),
    color: colors.primaryColor,
    marginRight: 2,
  },
  valueText: {
    fontFamily: fonts.medium,
    fontSize: RFValue(12),
    color: colors.text,
  },
});

export { styles };
